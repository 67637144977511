import { createReducer } from "@reduxjs/toolkit";
import { errorHandler } from "../../utils/error";
import * as actions from "../actions/authActions";

const initialState = {
  UID: null,
  isAuth: null,
  isFetching: false,
  isResetPasswordRequested: false,
  error: null,
};

const defaultRequest = (state, { payload }) => ({
  ...state,
  isFetching: true,
  error: null,
});

const defaultError = (state, { payload }) => ({
  ...state,
  isFetching: false,
  error: payload,
});

const authReducer = createReducer(initialState, {
  [actions.signinRequest]: (state) => ({
    ...state,
    UID: undefined,
    isAuth: false,
    isFetching: true,
    error: null,
  }),
  [actions.signinSuccess]: (state, { payload }) => ({
    ...state,
    UID: payload._id,
    isAuth: true,
    isFetching: true,
    error: null,
  }),
  [actions.signinError]: (state, { payload }) => ({
    ...state,
    UID: undefined,
    isAuth: false,
    isFetching: false,
    error: errorHandler(payload),
  }),
  [actions.signoutRequest]: (state, { payload }) => ({
    ...state,
    isFetching: true,
  }),
  [actions.signoutSuccess]: (state, { payload }) => ({
    ...state,
    UID: undefined,
    isAuth: false,
    isFetching: false,
    error: null,
  }),
  [actions.signoutError]: (state, { payload }) => ({
    ...state,
    UID: undefined,
    isAuth: false,
    isFetching: false,
    error: errorHandler(payload),
  }),
  [actions.recuperarSenhaRequest]: defaultRequest,
  [actions.recuperarSenhaError]: defaultError,
  [actions.recuperarSenhaSuccess](state, { payload }) {
    return {
      ...state,
      isResetPasswordRequested: true,
      isFetching: false,
      error: null,
    };
  },
  [actions.resetarSenhaRequest]: defaultRequest,
  [actions.resetarSenhaError]: defaultError,
  [actions.resetarSenhaSuccess](state, { payload }) {
    return {
      ...state,
      isResetPasswordRequested: false,
      isFetching: false,
      error: null,
    };
  },
  [actions.fetchLocalDataAction]: (state, { payload }) => ({
    ...state,
    isAuth: true,
    UID: payload._id,
  }),
});

export default authReducer;
