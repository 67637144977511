// import { getUserId } from '../session';
import { post, get } from './base';

const votoApi = {
  createVoto: (form, idAssembleia, idChapa, idAssociado) =>
    post(`/voto/${idAssembleia}/${idChapa}/${idAssociado}`, form),
  isApto: (idAssembleia, idAssociado) =>
    get(`/voto/apto/${idAssembleia}/${idAssociado}`),
};

export default votoApi;
