import React, { useCallback, useEffect, useState } from 'react';
import styles from '../scss/pages/_convenios.module.scss';

import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col, Spinner } from 'reactstrap';
import RodapeSection from '../components/RodapeSection';
import ConvenioCard from '../components/ConvenioCard';

import { fetchSearchConvenios } from '../redux/actions/convenioActions';
import { useSearchParams } from 'react-router-dom';
import ConvenioPesquisa from '../components/ConvenioPesquisa';
import ConvenioMapa from '../components/ConvenioMapa';
import { LocalizacaoPadrao } from '../utils/helpers';

const titulo = 'Convênios';
const subtitulo =
  'aproveite benefícios exclusivos em mais de 27 mil estabelecimentos em todo o Brasil';

const pageSize = 24;

const Convenios = ({ ...args }) => {
  const dispatch = useDispatch();
  const convenios = useSelector((state) => state.convenios.convenios);
  const isFetching = useSelector((state) => state.convenios.isFetching);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const [page, setPage] = useState(0);
  const [centro, setCentro] = useState(LocalizacaoPadrao);

  const [search, setSearch] = useSearchParams({
    nome: '',
    categoria: '',
    online: 0,
    loc: '',
  });

  useEffect(() => {
    const query = {
      nome: search.get('nome'),
      categoria: search.get('categoria'),
      online: !!+search.get('online'),
      localizacao: search.get('loc'),
    };
    if (!query.localizacao) {
      query.localizacao = String([
        LocalizacaoPadrao.lat,
        LocalizacaoPadrao.lng,
      ]);
    }
    dispatch(fetchSearchConvenios(query, page, pageSize));
    setCentro({
      lat: +query.localizacao.split(',')[0],
      lng: +query.localizacao.split(',')[1],
    });
  }, [dispatch, search, isAuth, page]);

  const handleSubmit = (values) => {
    setSearch(values);
  };

  const handleInfiniteScroll = useCallback(
    ({ target }) => {
      if (isFetching /* || !hasMore */) return;
      if (target.scrollTop >= target.scrollHeight - target.offsetHeight) {
        setPage((prev) => prev + 1);
      }
    },
    [isFetching]
  );

  return (
    <div id="page-convenios" className={styles['page-convenios']}>
      <Container className="page-titulo-container">
        <Row>
          <Col xs={12}>
            <h1>{titulo}</h1>
            <h2 className={styles.subtitulo}>{subtitulo}</h2>
          </Col>
        </Row>
      </Container>
      <div className={styles['conteudo-wrapper mb-3']}>
        <Container className="mb-3">
          <Row>
            <Col xs={12} className="d-flex align-items-center px-0">
              <ConvenioPesquisa action={handleSubmit} search={search} />
            </Col>
          </Row>
        </Container>
        <Container fluid className="py-4 bg-light h-100">
          <Row className="d-flex gap-5 gap-md-0 h-100">
            <Col
              xs={12}
              md={7}
              className={styles['resultados-wrapper']}
              onScroll={handleInfiniteScroll}
            >
              {isFetching && (
                <div className="loading-overlay">
                  <Spinner />
                </div>
              )}
              <div className={styles.resultados}>
                {convenios?.map((convenio, i) => (
                  <ConvenioCard key={i} convenio={convenio} />
                ))}
              </div>
            </Col>
            <Col xs={12} md={5} className={styles['mapa-wrapper']}>
              <ConvenioMapa centro={centro} />
            </Col>
          </Row>
        </Container>
      </div>
      <RodapeSection />
    </div>
  );
};

export default Convenios;
