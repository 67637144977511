import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions/convenioActions';

const initialState = {
  convenios: [],
  isFetching: false,
  error: null,
};

const convenioReducer = createReducer(initialState, {
  [actions.genericConvenioRequest]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [actions.genericConvenioError]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload,
  }),
  [actions.genericConvenioSuccess]: (state) => ({
    ...state,
    isFetching: false,
    error: null,
  }),
  [actions.searchConveniosSuccess]: (state, { payload }) => ({
    ...state,
    convenios: payload,
  }),
});

export default convenioReducer;
