import React from 'react';
import { Container, Row, Col } from 'reactstrap';


const QuemSomos = () => {
  return (
    <Container className="my-5 py-5 px-4 px-md-0">
      <Row className="mb-5">
        <Col xs={12}>
          <h2>Quem Somos</h2>
          <p className="text-justify">
            A ASCESA – Associação dos Colaboradores das Entidades do SICOOB e
            Afins é uma sociedade privada, sem fins lucrativos, que congrega os
            colaboradores do BANCO SICOOB – Cooperativas centrais e singulares
            do Sicoob – SICOOB Confederação – SICOOB Seguradora – SICOOB
            Administradora de Consórcio – SICOOB DTVM – Fundação Sicoob Previ –
            SICOOB PAGAMENTOS e cooperados de cooperativas do Sicoob.
          </p>
        </Col>
      </Row>
      <Row className="pt-5 d-flex justify-content-between gap-5 gap-md-0">
        <Col xs={12} md={3}>
          <h2>Missão</h2>
          <p className="text-justify">
            “Proporcionar opções de esporte, lazer, entretenimento e serviços,
            promovendo a integração e o desenvolvimento sócio cultural entre os
            associados e a comunidade.”
          </p>
        </Col>
        <Col xs={12} md={3}>
          <h2>Visão</h2>
          <p className="text-justify">
            “Ser reconhecida como a melhor opção de desenvolvimento, bem estar e
            motivo de orgulho dos associados.”
          </p>
        </Col>
        <Col xs={12} md={3}>
          
        </Col>
      </Row>
    </Container>
  );
};

export default QuemSomos;
