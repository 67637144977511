import React from 'react';
import Beneficios from './Beneficios';
import Hero from './Hero';
import '../../scss/pages/_home.scss';
import PesquisaCredenciados from './PesquisaCredenciados';
import Noticias from './NoticiasSection';
import SejaConveniado from './SejaConveniado';
import RodapeSection from '../../components/RodapeSection';

const Home = () => (
  <div id="homepage">
    <Hero />
    <Beneficios />
    <PesquisaCredenciados />
    <Noticias />
    <SejaConveniado />
    <RodapeSection />
  </div>
);

export default Home;
