import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';

const Logo = ({ size, variant, className, ...rest }) => {
  const logo =
    variant === 'white'
      ? require('../images/logo.png')
      : require('../images/logo-colorido.png');

  return (
    <img
      src={logo}
      alt="Logotipo ASCESA"
      loading="eager"
      className={clsx('logotipo-ascesa', className)}
      logosize={size}
      {...rest}
    />
  );
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['white', 'color']),
  size: PropTypes.oneOf(['small', 'medium', 'big']),
};
Logo.defaultProps = {
  variant: 'white',
  size: 'medium',
};

export default Logo;
