import { createAction } from '@reduxjs/toolkit';
import votoApi from '../../utils/api/votoApi';
import { errorHandler } from '../../utils/error';
import { displayError, displaySuccess } from './notificacoesActions';

export const createVotoRequest = createAction('CREATE_VOTO_REQUEST');
export const createVotoSuccess = createAction('CREATE_VOTO_SUCCESS');
export const createVotoError = createAction('CREATE_VOTO_ERROR');

export const isAptoRequest = createAction('GET_APTO_REQUEST');
export const isAptoSuccess = createAction('GET_APTO_SUCCESS');
export const isAptoError = createAction('GET_APTO_ERROR');

export const newVotoRequest = createAction('NEW_VOTO_REQUEST');

export const createVoto =
  (form, idAssembleia, idChapa, idAssociado) => async (dispatch) => {
    try {
      dispatch(createVotoRequest());
      const { data } = await votoApi.createVoto(
        form,
        idAssembleia,
        idChapa,
        idAssociado
      );
      dispatch(displaySuccess('Voto cadastrado com sucesso!'));
      dispatch(createVotoSuccess(data));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(createVotoError(errorHandler(e)));
      dispatch(displayError(errorHandler(e)));
      return false;
    }
  };

export const isApto = (idAssembleia, idAssociado) => async (dispatch) => {
  dispatch(isAptoRequest());
  try {
    const { data } = await votoApi.isApto(idAssembleia, idAssociado);
    dispatch(isAptoSuccess());
    dispatch(isAptoSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(isAptoError(errorHandler(error)));
    dispatch(displayError(error));
  }
};