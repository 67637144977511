import React, { useReducer } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useParams } from "react-router-dom";
import { Container, Spinner, Col, Button } from "reactstrap";
import { getAssembleia } from "../../redux/actions/assembleiaActions";
import { isApto } from "../../redux/actions/votoActions";
import { Multi, Step } from "../../shared/Multi";

import SimpleNotification from "../../components/SimpleNotification";

import AssembleiaConfirmacao from "./AssembleiaConfirmacao";
import AssembleiaDocumentos from "./AssembleiaDocumentos";
import AssembleiaEscolhaChapa from "./AssembleiaEscolhaChapa";
import AssembleiaInicio from "./AssembleiaInicio";
import AssembleiaObrigado from "./AssembleiaObrigado";
import AssembleiaValidacaoOTP from "./AssembleiaValidacaoOTP";

export const Assembleia = () => {
  const isFetching = useSelector((store) => store.assembleia.isFetching);
  const associado = useSelector((state) => state.usuario.usuario);
  const error = useSelector((store) => store.assembleia.error);
  const notificationError = useSelector((state) => state.notificacoes.error);
  const idAssociado = associado._id;
  const [userData, setUserData] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {}
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    id && dispatch(getAssembleia(id));
  }, [dispatch, id]);

  useEffect(() => {
    id && idAssociado && dispatch(isApto(id, idAssociado));
  }, [dispatch, id, idAssociado]);

  if (isFetching)
    return (
      <div className="loading-overlay">
        <Spinner />
      </div>
    );

  if (error) return <Navigate to="/404" replace />;

  return (
    <Container>
      <Multi value={{ userData, setUserData }}>
        <Step component={AssembleiaInicio} />
        <Step component={AssembleiaValidacaoOTP} />
        {notificationError && (
          <section className="mx-5 mt-4 text-center">
          <Col sm={12} md={6} className="">
            <SimpleNotification
              error={notificationError}
              className="mt-3 mb-3"
            />
            <Link to="/">
              <Button color="success">
                Voltar
              </Button>
            </Link>
          </Col>
        </section>
        )}
        <Step component={AssembleiaDocumentos} />
        <Step component={AssembleiaEscolhaChapa} />
        <Step component={AssembleiaConfirmacao} />
        <Step component={AssembleiaObrigado} />
      </Multi>
    </Container>
  );
};
