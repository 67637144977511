import React from "react";
import { Container, Row, Col } from "reactstrap";
import AssocieSe from "./AssocieSe";
import NossoCompromisso from "./NossoCompromisso";
import QuemSomos from "./QuemSomos";
import RodapeSection from "../../components/RodapeSection";
import Estatuto from "./Estatuto";

const titulo = "Institucional";

const Institucional = ({ ...args }) => {
  return (
    <div id="page-institucional">
      <Container className="page-titulo-container">
        <Row>
          <Col xs={12}>
            <h1>{titulo}</h1>
          </Col>
        </Row>
      </Container>
      <QuemSomos />
      <NossoCompromisso />
      <div className="quadro-links">
        <Estatuto />
        <AssocieSe />
      </div>
      <RodapeSection />
    </div>
  );
};

export default Institucional;
