import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FormAssocia } from './components';

export const Associa = () => {
  return (
    <div id="page-associar">
      <Container className="page-titulo-container">
        <Row>
          <Col>
            <h1>Associar-se</h1>
          </Col>
        </Row>
      </Container>
      <FormAssocia />
    </div>
  );
};
