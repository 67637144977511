import React, { useState } from "react";
import { Button, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Field, Form } from "react-final-form";

import SimpleNotification from "../../components/SimpleNotification";
import PdfModal from "../../components/PdfModal";
import { renderComponentField } from "../../shared/components/Form/FormField";

const TextArea = renderComponentField("textarea");

const AssembleiaDocumentos = ({ setUserData, next }) => {
  const assembleia = useSelector((state) => state.assembleia.assembleia);
  const associado = useSelector((state) => state.usuario.usuario);
  const error = useSelector((state) => state.notificacoes.error);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [justificativa, setJustificativa] = useState(false);

  const handleVoto = () => {
    setUserData({ aprovacaoDocumentos: 'aprovado' });
    next();
  };

  const handleJustificativaVoto = () => {
    setJustificativa(true);
  };

  const onSubmit = (data) => {
    setUserData({ justificativa: data.justificativa, aprovacaoDocumentos: 'reprovado' });
    next();
  };

  const handlePdf = (index) => () => {
    const documento = assembleia.documentos[index];
    const data = documento.conteudo.data.data;
    const titulo = documento.titulo;
    setCurrentPdf({ data, titulo });
    setModalOpen(true);
  };

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <section className="mx-5 mt-4 text-center d-flex justify-content-center">
      {justificativa ? (
        <Col sm={12} md={6}>
          <h2 className="pb-5">Justificativa</h2>
          <p className="pb-3">
            Sr(a). <b>{associado.nome}</b>, nos justifique sua reprovação!
          </p>
          <div className="pb-3 d-flex flex-column gap-2">
            <Form onSubmit={onSubmit}>
              {({
                values,
                handleSubmit,
                form,
                form: {
                  reset,
                  mutators: { push, remove },
                },
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="justificativa"
                          className="areajustificativa"
                          type="textarea"
                          component={TextArea}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <Button color="success">Justificar</Button>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
          <SimpleNotification error={error} className="mt-3 mb-3" />
        </Col>
      ) : (
        <Col sm={12} md={6}>
          <h2 className="pb-5">Documentos</h2>
          <p className="pb-3">
            Sr(a). <b>{associado.nome}</b>, você aprova a prestação de contas da
            diretoria?
          </p>
          <div className="pb-3 d-flex flex-column gap-2">
            {assembleia.documentos.map((item, i) => (
              <Button
                key={item._id}
                color="primary"
                className="btn-outline w-100"
                onClick={handlePdf(i)}
              >
                {item.titulo}
              </Button>
            ))}
          </div>
          <PdfModal
            open={modalOpen}
            toggle={toggleModal}
            data={currentPdf?.data}
            titulo={currentPdf?.titulo}
          />

          <SimpleNotification error={error} className="mt-3 mb-3" />

          <div className="d-flex justify-content-between">
            <Button color="danger" onClick={handleJustificativaVoto}>
              Reprovar
            </Button>
            <Button color="success" onClick={handleVoto}>
              Aprovar
            </Button>
          </div>
        </Col>
      )}
    </section>
  );
};

export default AssembleiaDocumentos;
