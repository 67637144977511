import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MultiContext } from './Multi';

const Step = ({ component: Component, ...props }) => {
  const [content, next, prev, setStep] = useContext(MultiContext);
  if (typeof Component === 'function') {
    return (
      <Component
        {...content}
        {...props}
        next={next}
        prev={prev}
        setStep={setStep}
      />
    );
  }
  return Component;
};

Step.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
    .isRequired,
};

export default Step;
