import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import MudarSenhaForm from '../components/MudarSenhaForm';

const MudarSenha = ({ ...args }) => {
  const navigate = useNavigate();
  const { token } = useParams();

  console.log(token)

  return (
    <Container className="d-flex justify-content-center pt-5">
      <MudarSenhaForm token={token}/>
    </Container>
  );
};

export default MudarSenha;
