import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const titulo = 'seja um conveniado';
const texto =
  'cadastre-se agora e disponibilize seus produtos e serviços para os associados da ASCESA.';
const imagem = require('../../images/ascesa-homem-seja-conveniado.png');

const SejaConveniado = ({ ...args }) => {
  return (
    <section id="seja-conveniado" className="pb-0">
      <Container>
        <Row>
          <Col md={6}>
            <img
              src={imagem}
              alt=""
              className="mx-auto d-block img-sejaconveniado"
              loading="lazy"
            />
          </Col>
          <Col
            md={{ offset: 1, size: 5 }}
            className="pe-0 pe-md-5 d-flex justify-content-center flex-column"
          >
            <h2>{titulo}</h2>
            <p>{texto}</p>
            <a href="mailto:associacao@sicoob.com.br" className="text-btn">
              <Button color="primary" className="btn-big btn-main mt-4">
                <span className="text-btn">cadastre-se</span>
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SejaConveniado;
