import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Hamburguer = ({ state, className }) => {
  return (
    <div
      className={classNames(className, 'hamburguer', {
        'hamburguer-open': state,
      })}
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

Hamburguer.propTypes = {
  state: PropTypes.bool,
};
Hamburguer.defaultProps = {
  state: false,
};

export default Hamburguer;
