import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * Impede usuarios autenticados de acessarem certas rotas
 * ex: Login, Cadastro
 */
const PublicGuard = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const initialAuth = useRef(isAuth);
  const [allow, setAllow] = useState(!isAuth);

  useEffect(() => {
    let timeout;
    if (isAuth !== initialAuth.current) {
      initialAuth.current = isAuth;
      timeout = setTimeout(() => setAllow(!isAuth), 2000);
    }
    return () => clearTimeout(timeout);
  }, [isAuth]);

  if (!allow) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default PublicGuard;
