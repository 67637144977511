import React, {
  createContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

export const MultiStates = {
  FINISH: 'Finished',
  ERROR: 'Error',
  LOADING: 'Loading',
};

export const MultiContext = createContext();

const Multi = ({ value, onFinish, state, children: _children }) => {
  const childList = useMemo(
    () => React.Children.toArray(_children),
    [_children]
  );
  const maxSteps = useRef(childList.length);
  const [step, setStep] = useState(0);

  const children = useMemo(() => {
    const states = {};
    const list = childList.filter((child) => {
      if (child.props.state) {
        states[child.props.state] = child;
        return false;
      }
      return true;
    });
    maxSteps.current = list.length;
    return { ...list, ...states };
  }, [childList]);

  const finish = () => {
    onFinish(value);
    children[MultiStates.FINISH] && setStep(MultiStates.FINISH);
  };

  const next = () => {
    if (step + 1 >= maxSteps.current) {
      finish();
      return;
    }
    setStep(step + 1);
  };

  const prev = () => {
    if (step - 1 < 0) return;
    setStep(step - 1);
  };

  useEffect(() => {
    state !== null && state !== undefined && setStep(state);
  }, [state]);

  return (
    <MultiContext.Provider value={[value, next, prev, setStep]}>
      {children[(isNaN(step) && step) || step % maxSteps.current]}
    </MultiContext.Provider>
  );
};

Multi.propTypes = {
  value: PropTypes.any,
  onFinish: PropTypes.func,
  error: PropTypes.bool,
  loading: PropTypes.bool,
};
Multi.defaultProps = {
  value: {},
  onFinish: () => {},
  error: false,
  loading: false,
};

export default Multi;
