import { createAction } from '@reduxjs/toolkit';
import noticiaApi from '../../utils/api/noticiaApi';
import { errorHandler } from '../../utils/error';
import { displayError } from './notificacoesActions';
import { arrayToBase64 } from '../../utils/helpers';

export const newNoticiaAction = createAction('NEW_NOTICIA');
export const clearNoticiasAction = createAction('CLEAR_NOTICIAS');

export const genericNoticiaRequest = createAction('GENERIC_NOTICIA_REQUEST');
export const genericNoticiaSuccess = createAction('GENERIC_NOTICIA_SUCCESS');
export const genericNoticiaError = createAction('GENERIC_NOTICIA_ERROR');

export const fetchNoticiasSuccess = createAction('FETCH_NOTICIAS_SUCCESS');
export const getNoticiaSuccess = createAction('GET_NOTICIA_SUCCESS');

export const fetchNoticias = (page, size) => async (dispatch) => {
  dispatch(genericNoticiaRequest());
  try {
    const { data } = await noticiaApi.fetch(page, size);
    data.docs = data.docs.map((noticia) => {
      if (noticia.imagem?.data?.data) {
        noticia.imagem = arrayToBase64(noticia.imagem.data.data);
      }
      return noticia;
    });
    dispatch(genericNoticiaSuccess());
    dispatch(fetchNoticiasSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(genericNoticiaError(errorHandler(error)));
  }
};

export const getNoticia = (id) => async (dispatch) => {
  dispatch(genericNoticiaRequest());
  try {
    const { data } = await noticiaApi.get(id);
    if (data.imagem?.data?.data) {
      data.imagem = arrayToBase64(data.imagem.data.data);
    }
    dispatch(genericNoticiaSuccess());
    dispatch(getNoticiaSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(genericNoticiaError(errorHandler(error)));
    dispatch(displayError('Ocorreu um erro carregar a notícia'));
  }
};

export const newNoticia = () => (dispatch) => {
  dispatch(newNoticiaAction());
};

export const clearNoticias = () => (dispatch) => {
  dispatch(clearNoticiasAction());
};
