import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions/noticiaActions';

const initialState = {
  noticia: {},
  noticias: [],
  totalPages: 0,
  isFetching: false,
  error: null,
};

const noticiaReducer = createReducer(initialState, {
  [actions.newNoticiaAction]: (state) => ({
    ...state,
    noticia: initialState.noticia,
    error: initialState.error,
  }),
  [actions.clearNoticiasAction]: (state) => ({
    ...state,
    noticias: [],
    totalPages: 0,
  }),
  [actions.genericNoticiaRequest]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [actions.genericNoticiaError]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload,
  }),
  [actions.genericNoticiaSuccess]: (state) => ({
    ...state,
    isFetching: false,
    error: null,
  }),
  [actions.fetchNoticiasSuccess]: (state, { payload }) => ({
    ...state,
    noticias: payload.docs,
    totalPages: payload.totalPages,
  }),
  [actions.getNoticiaSuccess]: (state, { payload }) => ({
    ...state,
    noticia: payload,
  }),
});

export default noticiaReducer;
