export const errorHandler = (errorResponse) => {
  let error = errorResponse;
  if (errorResponse?.response?.data) {
    error = errorResponse.response.data;
  }
  const result =
    error?.err ||
    error?.erro ||
    error?.error ||
    error?.mensagem ||
    error?.message ||
    error;
  return result;
};
