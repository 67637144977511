import { useEffect, useMemo, useState } from 'react';
const markerIcon = require('../../images/pin-mapa.png');

const Marker = ({ map, position, title, onClick }) => {
  const [marker, setMarker] = useState();

  const markerOptions = useMemo(
    () => ({
      animation: window.google.maps.Animation.DROP,
      optimized: false,
      map,
      position,
      title,
      icon: markerIcon,
    }),
    [map, position, title]
  );

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker(markerOptions));
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, markerOptions]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(markerOptions);
    }
  }, [marker, markerOptions]);

  useEffect(() => {
    if (marker) {
      marker.addListener('click', (e) => onClick(marker, e));
    }
  }, [marker, onClick]);

  return null;
};

export default Marker;
