import { createAction } from '@reduxjs/toolkit';

export const toggleLoginModalAction = createAction('TOGGLE_LOGIN_MODAL');

export const toggleLoginModal = (state) => (dispatch, getState) => {
  const isAuth = getState().auth.isAuth;
  const finalState =
    state === undefined ? (isAuth === true ? false : state) : state;
  dispatch(toggleLoginModalAction(finalState));
};
