import { regexNaN } from './constantes';

export const sexoList = [
  { value: 'masculino', label: 'Masculino' },
  { value: 'feminino', label: 'Feminino' },
];

export const estadoCivilList = [
  { value: 'casado', label: 'Casado(a)' },
  { value: 'solteiro', label: 'Solteiro(a)' },
  { value: 'viúvo', label: 'Viúvo(a)' },
  { value: 'divorciado', label: 'Divorciado(a)' },
  { value: 'outros', label: 'Outros' },
];

export const listEmpresaAssociada = [
  {
    value: 'Cooperado de cooperativa do SICOOB',
    label: 'Cooperado de cooperativa do SICOOB',
  },
  { value: 'BANCO SICOOB', label: 'BANCO SICOOB' },
  { value: 'SICOOB DTVM', label: 'SICOOB DTVM' },
  { value: 'SICOOB PAGAMENTOS', label: 'SICOOB PAGAMENTOS' },
  {
    value: 'Cooperativas centrais e singulares do SICOOB',
    label: 'Cooperativas centrais e singulares do SICOOB',
  },
  { value: 'Fundação SICOOB Previ', label: 'Fundação SICOOB Previ' },
  {
    value: 'SICOOB Administradora de Consórcio',
    label: 'SICOOB Administradora de Consórcio',
  },
  { value: 'SICOOB Confederação', label: 'SICOOB Confederação' },
  { value: 'SICOOB Seguradora', label: 'SICOOB Seguradora' },
];

export const listaCategoriasConvenio = [
  {
    label: 'Todas',
    value: '',
  },
  {
    label: 'Comer & Beber',
    value: '59fd207f2d84ff11f8edcaee',
  },
  {
    label: 'Cultura & Lazer',
    value: '59fd207f2d84ff11f8edcaef',
  },
  {
    label: 'Turismo',
    value: '59fd207f2d84ff11f8edcaf0',
  },
  {
    label: 'Cursos',
    value: '59fd207f2d84ff11f8edcaf1',
  },
  {
    label: 'Saúde e Esportes',
    value: '59fd207f2d84ff11f8edcaf2',
  },
  {
    label: 'Lojas',
    value: '59fd207f2d84ff11f8edcaf3',
  },
  {
    label: 'Profissionais',
    value: '59fd207f2d84ff11f8edcaf5',
  },
  {
    label: 'Farmácias',
    value: '59fd207f2d84ff11f8edcaf7',
  },
  {
    label: 'Beleza & Bem-estar',
    value: '59fd207f2d84ff11f8edcaf8',
  },
  {
    label: 'Serviços',
    value: '59fd207f2d84ff11f8edcaf9',
  },
];

export const LocalizacaoPadrao = {
  lat: -15.795353405141633,
  lng: -47.91389215577846,
};

export const formatarCpf = (value) => {
  // value = '12312312312';
  return value
    ?.replace(regexNaN, '')
    ?.replace(/(\d{3})(\d)/, '$1.$2')
    ?.replace(/(\d{3})(\d)/, '$1.$2')
    ?.replace(/(\d{3})(\d)/, '$1-$2')
    ?.replace(/(-\d{2})\d+?$/, '$1');
};

export const cepMask = (value) => {
  return value
    .replace(regexNaN, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

export const maskPhone = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};

export const maskPhoneComercial = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};

export const formatFields = (fields, formRef) => {
  const { values } = formRef.current.getState();
  for (let field of fields) {
    const { name, mask } = field;
    values[name] && formRef.current.change(name, mask(values[name]));
  }
};

export const noMask = (value) => {
  return value?.replace(regexNaN, '');
};

export const parseDistanceKm = (value) => {
  let metro = +value <= 1;
  if (metro) {
    return `${Math.round(value * 1000)} m`;
  }
  return `${value.toFixed(2)} km`;
};

export const arrayToBase64 = (
  value,
  { type = 'image/png', raw = false } = {}
) => {
  let array = new Uint8Array(value);
  let result = array.reduce(
    (data, byte) => data + String.fromCharCode(byte),
    ''
  );
  if (raw) return btoa(result);
  return `data:${type};base64,${btoa(result)}`;
};
