import React, { useEffect, useState } from "react";
import styles from "../scss/components/_login-form.module.scss";
import PropTypes from "prop-types";
import clsx from "classnames";

import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Button, Spinner } from "reactstrap";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import CheckBoldIcon from "mdi-react/CheckBoldIcon";
import Logo from "./Logo";

import { resetarSenha } from "../redux/actions/authActions";
import SimpleNotification from "./SimpleNotification";
import { clearAll} from "../redux/actions/notificacoesActions";

const MudarSenhaForm = ({ logo, onFinish, toggle, className, token }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFetching = useSelector((state) => state.auth.isFetching);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const success = useSelector((state) => state.notificacoes.success);
  const error = useSelector((state) => state.notificacoes.error);

  const [senhaNaoIgualError, setSenhaNaoIgualError] = useState()

  const handleSubmit = ({ senha, confirmSenha }) => {

    if (senha === confirmSenha) {
      dispatch(resetarSenha(senha, token));
    } else if (senha !== confirmSenha) {
      setSenhaNaoIgualError('As senhas precisam ser iguais');
      console.log(senhaNaoIgualError)
    }
    dispatch(clearAll());
  };

  const redirecionarLogin = () => {
    toggle && toggle();
    navigate("/login");
  };

  useEffect(() => () => dispatch(clearAll()), [dispatch]);
  return (
    <div className={clsx(styles["login-form__wrapper"], className)}>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit, errors, touched }) => {
          const senhaError =
            errors["password"] && touched["password"] ? "error" : undefined;
          return (
            <form
              onSubmit={handleSubmit}
              className={clsx(styles["login-form"], "container-fluid")}
            >
              <Row>
                {logo && (
                  <Col xs={12} className="pb-2">
                    <Logo variant="color" size="big" />
                  </Col>
                )}
                <Col xs={12}>
                  <h2>Recuperação da Senha</h2>
                </Col>

                <Col xs={12}>
                  <SimpleNotification success={success} error={error} />
                </Col>
              </Row>
              <Row xs={12} className={styles["login-form__content"]}>
                <Col xs={12} className="p-0">
                  <Field
                    component="input"
                    name="senha"
                    id="senha"
                    placeholder="Senha"
                    className="input-border w-100"
                    type="password"
                    error={senhaError}
                  />
                </Col>
                <Col xs={12} className="p-0">
                  <Field
                    component="input"
                    name="confirmSenha"
                    id="confirmarSenha"
                    placeholder="Confirmar Senha"
                    className="input-border w-100"
                    type="password"
                    error={senhaError}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-1">
                  {!success && (
                    <Button
                      color="primary"
                      disabled={isFetching || isAuth}
                      className="px-5"
                    >
                      {isAuth
                        ? "Autenticado"
                        : isFetching
                        ? "Autenticando"
                        : "Mudar"}
                    </Button>
                  )}
                  {success && (
                    <Button
                      color="primary"
                      disabled={isFetching || isAuth}
                      className="px-5"
                      onClick={redirecionarLogin}
                    >
                      Fazer Login
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          );
        }}
      </Form>
      {(isFetching || isAuth) && (
        <div
          className={clsx(styles["login-form__loading"], {
            [styles["login-form__success"]]: isAuth,
          })}
        >
          {isAuth ? <CheckBoldIcon /> : <Spinner />}
        </div>
      )}
    </div>
  );
};

MudarSenhaForm.propTypes = {
  logo: PropTypes.bool,
  toggle: PropTypes.func,
  onFinish: PropTypes.func,
  className: PropTypes.string,
};
MudarSenhaForm.defaultProps = {
  logo: true,
};

export default MudarSenhaForm;
