import { createReducer } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error';
import * as actions from '../actions/assembleiaActions';

const initialState = {
  assembleias: [],
  totalPages: 0,
  assembleia: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

const assembleiaReducer = createReducer(initialState, {
  [actions.getAssembleRequest]: (state) => ({
    ...state,
    assembleia: {},
    isFetching: true,
    error: null,
  }),
  [actions.getAssembleSuccess]: (state, { payload }) => ({
    ...state,
    assembleia: payload,
    isFetching: false,
    error: null,
  }),
  [actions.getAssembleError]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: errorHandler(payload),
  }),
});

export default assembleiaReducer;
