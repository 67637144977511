import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './multi-input.scss';

const MultiInput = ({
  meta: { error },
  amount,
  name,
  placeholder,
  className,
  onChange,
}) => {
  const values = useRef(new Array(amount));
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);

  const checkError = () => {
    for (let v of values.current) {
      if (!v) return true;
    }
    return false;
  };

  const hasError = touched && (error || checkError());
  const wrapperClass = classNames('multi-input', {
    'multi-input--error': hasError,
  });

  const handleChange = useCallback(
    (n) => (e) => {
      values.current[n] = e.target.value;
      let newValue = '';
      for (let v of values.current) {
        newValue += v || '';
      }
      setValue(newValue);

      if (e.target.value.length >= 1 && n < amount - 1) {
        e.target.parentElement.childNodes[n + 1].focus();
      }
    },
    [amount]
  );

  const handleKey = useCallback(
    (n) => (e) => {
      if (e.key === 'ArrowRight' && n < amount - 1) {
        e.target.parentElement.childNodes[n + 1].focus();
        return;
      }

      if (e.key === 'ArrowLeft' && n > 0) {
        e.target.parentElement.childNodes[n - 1].focus();
      }
    },
    [amount]
  );

  useEffect(() => {
    onChange && onChange(value);
  }, [value, onChange]);

  const generateInputs = useCallback(() => {
    const n = amount;
    const elements = [];
    const hasManyPH = Array.isArray(placeholder);
    for (let i = 0; i < n; i++) {
      elements.push(
        <input
          key={`multiinput-${name}-${i + 1}`}
          type="text"
          placeholder={hasManyPH ? placeholder[i] : placeholder}
          maxLength={1}
          onFocus={(e) => e.target.setSelectionRange(0, 1)}
          onKeyDown={handleKey(i)}
          onKeyUp={(e) => e.target.setSelectionRange(0, 1)}
          onChange={handleChange(i)}
        />
      );
    }
    return elements;
  }, [amount, name, placeholder, handleChange, handleKey]);

  return (
    <div
      className={className}
      onBlur={() => setTouched(true)}
      onFocus={() => setTouched(false)}
    >
      <div className={wrapperClass}>{generateInputs()}</div>
      <input type="hidden" name={name} value={value} />
    </div>
  );
};

MultiInput.propTypes = {
  amount: PropTypes.number.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.any,
};
MultiInput.defaultProps = {};

export default MultiInput;
