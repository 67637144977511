import { createReducer } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error';
import * as actions from '../actions/votoActions';

const initialState = {
  votos: [],
  voto: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

const votoReducer = createReducer(initialState, {
  [actions.createVotoRequest]: (state) => ({
    ...state,
    isUpdating: true,
    error: null,
  }),
  [actions.createVotoSuccess]: (state, { payload }) => ({
    ...state,
    voto: payload,
    isUpdating: false,
    error: null,
  }),
  [actions.createVotoError]: (state, { payload }) => ({
    ...state,
    isUpdating: false,
    error: errorHandler(payload),
  }),
  [actions.newVotoRequest]: (state, { payload }) => ({
    ...state,
    voto: {},
    isFetching: false,
    isUpdating: false,
    error: errorHandler(payload),
  }),
  [actions.isAptoRequest]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [actions.isAptoError]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: payload,
  }),
  [actions.isAptoSuccess]: (state) => ({
    ...state,
    isFetching: false,
    error: null,
  }),
});


export default votoReducer;
