import { createAction } from '@reduxjs/toolkit';
import { displayError, displaySuccess } from './notificacoesActions';
import associadoApi from '../../utils/api/associadoApi';
import { errorHandler } from '../../utils/error';

export const createAssociarSeRequest = createAction(
  'CREATE_ASSOCIAR_SE_REQUEST'
);
export const createAssociarSeSuccess = createAction(
  'CREATE_ASSOCIAR_SE_SUCCESS'
  );
  
export const getQrCodeRequest = createAction('CREATE_QRCODE_REQUEST');
export const getQrCodeSuccess = createAction('CREATE_QRCODE_SUCCESS');
export const getQrCodeError = createAction('CREATE_QRCODE_ERROR');

export const createAssociarSeError = createAction('CREATE_ASSOCIAR_SE_ERROR');

export const newAssociadoRequest = createAction('NEW_ASSOCIADO_REQUEST');

export const createAssociarSe = (form) => async (dispatch) => {
  try {
    dispatch(createAssociarSeRequest());
    const { data } = await associadoApi.createAssociase(form);
    dispatch(displaySuccess('Usuário associado com sucesso!'));
    dispatch(createAssociarSeSuccess(data));
    return true;
  } catch (e) {
    console.log(e);
    dispatch(createAssociarSeError(e));
    dispatch(displayError(e));
    return false;
  }
};

export const getQrCode = (token) => async (dispatch) => {
  try {
    dispatch(getQrCodeRequest());
    const { data } = await associadoApi.getQrCode(token);
    dispatch(displaySuccess());
    dispatch(getQrCodeSuccess(data));
  } catch (e) {
    console.error(e);
    dispatch(getQrCodeError(errorHandler(e)))
    dispatch(displayError(e))
  }
}