import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import '../scss/components/_footer.scss';

import LogoGooglePlay from '../images/badge-google-play.png';
import LogoAppStore from '../images/badge-app-store.png';

const RodapeSection = ({ ...args }) => {
  return (
    <section className="section footer bg-light" {...args}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h6 className="fw-bold">
                BAIXE NOSSO APLICATIVO NAS PLATAFORMAS
              </h6>
              <img
                src={LogoGooglePlay}
                alt="Google Play"
                className="img-lojas mt-4 lazy"
                loading="lazy"
              />
              <img
                src={LogoAppStore}
                alt="App Store"
                className="img-lojas mt-4 lazy"
                loading="lazy"
              />
            </div>
            <hr className="my-5" />
            <div className="text-center">
              <p className="text-muted">
                A ASCESA – Associação dos Colaboradores das Entidades do SICOOB
                e Afins é uma sociedade privada, sem fins lucrativos, que
                congrega os colaboradores do BANCO SICOOB – Cooperativas
                centrais e singulares do Sicoob – Sicoob Confederação – SICOOB
                Seguradora – SICOOB Administradora de Consórcio – SICOOB DTVM –
                Fundação Sicoob Previ – SICOOB PAGAMENTOS e os cooperados de uma
                das cooperativas do Sicoob.
              </p>
              <p className="text-muted">
                Oferecemos aos nossos associados:
                <br />
                Rede de atendimento diferenciada;
                <br />
                Oportunidade de participação em programas sociais;
                <br />
                Rede colaborativa de parcerias e benefícios, com abrangência
                nacional, oferencendo vantagens e descontos em mais de 27 mil
                estabelecimentos.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RodapeSection;
