import React from 'react';
import { Container, Row, Col, Button, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

const titulo = 'associe-se';
const texto =
  'Associe-se agora e aproveite benefícios exclusivos em mais de 27 mil estabelecimentos em todo o Brasil';

const AssocieSe = ({ ...args }) => {
  return (
    <Container className="mb-5">
      <Row className="d-flex justify-content-center">
        <Col xs={12} md={8}>
          <Card
            body
            className="bg-light border-0 d-flex gap-3 flex-column p-5 pt-4 pb-4 h-100"
          >
            <div>
              <h2 className="fw-bold">{titulo}</h2>
              <p>{texto}</p>
            </div>
            <Link to="/associe-se" className="text-btn">
              <Button color="primary">
                <span className="text-btn">Associe-se agora</span>
              </Button>
            </Link>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AssocieSe;
