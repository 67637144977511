import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';

const AssembleiaEscolhaChapa = ({ userData, setUserData, next }) => {
  const assembleia = useSelector((state) => state.assembleia.assembleia);
  const chapas = assembleia.chapas;
  const isFetching = useSelector((state) => state.assembleia.isFetching);
  const associado = useSelector((state) => state.usuario.usuario);
  const [clicksError, setClicksError] = useState(0);

  const handleVoteSubmit = (data) => {
    const values = { ...data };
    setUserData(values);
  };

  const handleSubmit = () => {
    if (Object.keys(userData).length > 0) {
      setClicksError((prev) => prev - prev);
      next();
    }
    setClicksError((prev) => prev + 1);
  };

  return (
    <>
      <section className="mx-5 mt-4 text-center">
        <Col sm={12} md={6} className="offset-3">
          <h2 className="pb-5">
            Registro de voto na assembléia: {assembleia.nome}
          </h2>
          <p className="pb-5">
            Sr(a). <strong>{associado.nome}</strong>, qual a sua escolha para a
            diretoria da ASCESA?
          </p>

          {isFetching && <Spinner />}
          {chapas && !isFetching && (
            <>
              {chapas?.map((item) => (
                <Button
                  key={item._id}
                  block
                  style={{ '--cor': `${item.cor.hex}` }}
                  className="my-2 btn-assembleia-voto"
                  onClick={() => handleVoteSubmit(item)}
                >
                  {item.nome}
                </Button>
              ))}
            </>
          )}

          {clicksError > 0 && Object.keys(userData).length === 0 && (
            <Row>
              <Col md={12}>
                <span className="span-validate p-10">Selecione uma chapa</span>
              </Col>
            </Row>
          )}
          <Button
            color="primary"
            type="button"
            className="mt-5"
            onClick={() => handleSubmit()}
          >
            Registrar Voto
          </Button>
        </Col>
      </section>
    </>
  );
};

export default AssembleiaEscolhaChapa;
