import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../layout/navbar/Layout';
import LoginModal from '../components/LoginModal';
import Preload from '../components/Preload';
import PublicGuard from './PublicGuard';
import PrivateGuard from './PrivateGuard';

/* Paginas */
import Home from '../pages/Home';
import { Associa } from '../pages/Cadastro/Associa';
import Login from '../pages/Login';
import RecuperarSenha from '../pages/RecuperarSenha';
import Noticia from '../pages/Noticia';
import Convenios from '../pages/Convenios';
import Institucional from '../pages/Institucional';
import { Assembleia } from '../pages/Assembleia';
import Noticias from '../pages/Noticias';
import MudarSenha from '../pages/MudarSenha';
import ValidateQrCode from '../pages/ValidadeQrCode/ValidadeQrCode';
import Privacidade from '../pages/privacidade/Privacidade';
import ExclusaoUsuario from '../pages/ExclusaoUsuario';

const Router = ({ ...args }) => {
  return (
    <BrowserRouter>
      <main className="main">
        <LoginModal />
        <Routes>
          <Route element={<Preload />}>
            {/* Rotas com validação de autenticação
             *  ex: Protegidas, Publicas
             */}
            <Route element={<PrivateGuard />}>
              {/*---- Rotas Privadas ----*/}
              <Route
                path="/assembleia/:id"
                element={
                  <>
                    <Layout />
                    <Assembleia />
                  </>
                }
              />
              <Route
                path="/exclusao-dados"
                element={
                  <>
                    <Layout />
                    <ExclusaoUsuario />
                  </>
                }
              />
            </Route>
            <Route element={<PublicGuard />}>
              {/*---- Rotas Públicas ----*/}
              <Route
                path="/associe-se"
                element={
                  <>
                    <Layout />
                    <Associa />
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <>
                    <Layout />
                    <Login />
                  </>
                }
              />
              <Route
                path="/recuperar-senha"
                element={
                  <>
                    <Layout />
                    <RecuperarSenha />
                  </>
                }
              />
              <Route
                path="/mudar-senha/:token"
                element={
                  <>
                    <Layout />
                    <MudarSenha />
                  </>
                }
              />
            </Route>
            <Route
              path="/pesquisar-convenios"
              element={
                <>
                  <Layout />
                  <Convenios />
                </>
              }
            />
          </Route>
          {/*---- Rotas Abertas ----*/}
          <Route
            path="/"
            element={
              <>
                <Layout />
                <Home />
              </>
            }
          />
          <Route
            path="/noticia/:noticiaId"
            element={
              <>
                <Layout />
                <Noticia />
              </>
            }
          />
          <Route
            path="/noticias"
            element={
              <>
                <Layout />
                <Noticias />
              </>
            }
          />
          <Route
            path="institucional"
            element={
              <>
                <Layout />
                <Institucional />
              </>
            }
          />
          <Route
            path="/validar-qr-code/:token"
            element={
              <>
                <Layout />
                <ValidateQrCode />
              </>
            }
          />
          <Route
            path="politica-de-privacidade"
            element={
              <>
                <Layout />
                <Privacidade />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <Layout />
                <h1>404</h1>
              </>
            }
          />
        </Routes>
      </main>
    </BrowserRouter>
  );
};

export default Router;
