import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions/bannerActions';

const initialState = {
  banner: {},
  banners: [],
  totalPages: 0,
  totalItems: 0,
  isFetching: false,
  error: null,
};

const bannerReducer = createReducer(initialState, {
  [actions.fetchBannersRequest]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [actions.fetchBannersSuccess]: (state, { payload }) => ({
    ...state,
    banners: payload.docs,
    totalItems: payload.totalDocs,
    totalPages: payload.totalPages,
    isFetching: false,
    error: null,
  }),
});

export default bannerReducer;
