export const API = process.env.REACT_APP_API_URL;
export const JWT_KEY = 'ascesa';
export const HAS_SESSION_STORAGE = 'session-storage';
export const HAS_LOCAL_STORAGE = 'local-storage';

export const regexNaN = /\D+/g;

export const regexMaskTelefone =
  /^(?<ddd>\d{2})(?<first>\d{1,5})?(?<last>\d{4})?$|(?<long>\d{12,})/;

export const CategoriaConvenioEnum = {
  Comer_e_Beber: '59fd207f2d84ff11f8edcaee',
  Cultura_e_Lazer: '59fd207f2d84ff11f8edcaef',
  Turismo: '59fd207f2d84ff11f8edcaf0',
  Cursos: '59fd207f2d84ff11f8edcaf1',
  Saude_e_Esportes: '59fd207f2d84ff11f8edcaf2',
  Lojas: '59fd207f2d84ff11f8edcaf3',
  Profissionais: '59fd207f2d84ff11f8edcaf5',
  Farmácias: '59fd207f2d84ff11f8edcaf7',
  Beleza_e_BemEstar: '59fd207f2d84ff11f8edcaf8',
  Servicos: '59fd207f2d84ff11f8edcaf9',
};
