import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import CustomCard from '../../components/CustomCard';
import { fetchNoticias } from '../../redux/actions/noticiaActions';
import { API } from '../../utils/constantes';

const titulo = 'notícias';
const texto = 'fique ligado nas novidades sobre a ASCESA';
//const mais = 'veja mais noticias';

const Noticias = ({ ...args }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noticias = useSelector((state) => state.noticias.noticias);

  const handleNavigate = (id) => () => {
    window.scrollTo(0, 0);
    navigate(`/noticia/${id}`);
  };

  useEffect(() => {
    dispatch(fetchNoticias(1, 4));
  }, [dispatch]);

  return (
    <section id="noticias">
      <Container>
        <Row className="mb-4">
          <Col xs={12} className="text-center">
            <h2>{titulo}</h2>
            <Container>
              <Row>
                <Col xs={12} lg={{ size: 4, offset: 4 }}>
                  <p>{texto}</p>
                </Col>
                <Col xs={12} lg={4}>
                  <Link to="/noticias" className="btn btn-minimal btn-info">
                    ver mais notícias
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {noticias.map((noticia, i) => (
            <Col key={i} md={3}>
              <CustomCard
                imagem={`${API}/noticia/imagem/${noticia._id}`}
                titulo={noticia.titulo}
                texto={noticia.descricao}
                action={handleNavigate(noticia._id)}
                wholeCardAction
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Noticias;
