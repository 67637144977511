import React, { useEffect, useReducer, useRef } from 'react';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { Form, FormSpy, Field } from 'react-final-form';
import {
  formatarCpf,
  formatFields,
  noMask,
  sexoList,
} from '../../../utils/helpers';
import Select from '../../../shared/components/Form/Select';
import FormField from '../../../shared/components/Form/FormField';
import moment from 'moment';
import { validateDependente } from './validate';
import { v4 as uuid } from 'uuid';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'ADD':
      return [...state, payload];
    case 'REMOVE':
      return state.filter((_, i) => i !== payload);
    default:
      return [...state];
  }
};

export const DadosFamiliaDependentes = ({
  userData,
  setUserData,
  next,
  prev,
  setStep,
  progressBar,
}) => {
  const [dependente, setDependente] = useReducer(
    reducer,
    userData.dependentes || []
  );
  const formRef = useRef();

  const gerarTable = () => {
    const values = [...dependente];
    if (values !== null && values !== undefined) {
      return values.map((item, index) => {
        const sexo = item?.sexo;
        const nome = item?.nome;
        const dataNascimento = item?.dataNascimento;
        const cpf = item?.cpf;
        return (
          <tr key={uuid()}>
            <td>{nome}</td>
            <td>{moment(dataNascimento).format('DD/MM/YYYY')}</td>
            <td>{formatarCpf(cpf)}</td>
            <td>{sexo ? sexo.charAt(0).toUpperCase() + sexo.slice(1) : ''}</td>
            <td>
              <button
                onClick={() => {
                  setDependente({ type: 'REMOVE', payload: index });
                }}
              >
                <span className="lnr lnr-trash" />
              </button>
            </td>
          </tr>
        );
      });
    }
  };

  const handleSubmit = (values) => {
    const data = { ...values };

    if (Object.keys(data).length > 0) {
      if (data) {
        data.sexo = data.sexo.value;
      }
      data.cpf = data.cpf ? noMask(data?.cpf) : null;
      // data.dataNascimento = noMask(data?.dataNascimento);
    }
    setDependente({ type: 'ADD', payload: data });

    formRef.current.restart();
  };

  const handleClick = () => {
    prev();
    progressBar(-20);
  }

  const handleClickNext = () => {
    next();
    progressBar(20)
  }

  useEffect(() => {
    setUserData({ dependentes: dependente });
  }, [dependente, setUserData]);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validateDependente(values)}
    >
      {({ handleSubmit, form }) => {
        formRef.current = form;
        return (
          <form id="form-dadosDependentes" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ value: true }}
              onChange={({ values }) => {
                const fields = [{ name: 'cpf', mask: formatarCpf }];
                formatFields(fields, formRef);
              }}
            />
            <Container className="margin-input-from-img">
              <Row>
                <Col md={12}>
                  <label className="label-mt-top">Nome do Dependente</label>
                  <Field
                    component={FormField}
                    name="nome"
                    className="input-border w-100"
                    type="text"
                    placeholder="Ex: Lucas Soares"
                    maxLength="50"
                  />
                </Col>

                <Col md={12}>
                  <label className="label-mt-top">
                    Data de Nascimento do Dependente
                  </label>
                  <Field
                    name="dataNascimento"
                    component={FormField}
                    className="input-border w-100"
                    type="date"
                  />
                </Col>
                <Col md={12}>
                  <label className="label-mt-top">CPF do Dependente</label>
                  <Field
                    name="cpf"
                    className="input-border w-100"
                    placeholder="Ex: 000.000.000-00"
                    maxLength="14"
                    component={FormField}
                    type="string"
                    rules={{ required: 'Campo Obrigatório' }}
                    format={(cpf) => (cpf ? formatarCpf(cpf) : '')}
                  />
                </Col>
                <Col md={12}>
                  <label className="label-mt-top">Sexo do Dependente</label>
                  <Field
                    type="select"
                    name="sexo"
                    id="selectDependentesSexo"
                    className="font-Input-value w-100"
                    component={Select}
                    options={sexoList}
                    placeholder="Selecione um campo"
                  />
                </Col>
              </Row>
            </Container>
            <Button type="submit" color="primary" className="mt-5 m-3">
              Salvar Dependente
            </Button>

            {Object.keys(dependente).length > 0 && (
              <Table className="small-margin-area">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Data de Nascimento</th>
                    <th>CPF</th>
                    <th>Sexo</th>
                  </tr>
                </thead>
                <tbody>{gerarTable(dependente, setDependente)}</tbody>
              </Table>
            )}

            <Row>
              <Col>
                <Button
                  color="gray"
                  className="mt-5 m-3"
                  onClick={handleClick}
                  /*className="btn-style-anterior"*/
                >
                  anterior
                </Button>
                <Button
                  onClick={handleClickNext}
                  color="primary"
                  className="mt-5 m-3" /*className="btn-style-prox"*/
                >
                  Próximo
                </Button>
              </Col>
            </Row>
          </form>
        );
      }}
    </Form>
  );
};
