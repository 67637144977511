import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions/modalsActions';

const initialState = {
  login: false,
};

const modalsReducer = createReducer(initialState, {
  [actions.toggleLoginModalAction]: (state, { payload }) => ({
    ...state,
    login: payload !== undefined ? payload : !state.login,
  }),
});

export default modalsReducer;
