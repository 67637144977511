import { createAction } from '@reduxjs/toolkit';
import mensagemApi from '../../utils/api/mensagemApi';
import { errorHandler } from '../../utils/error';
import { displayError, displaySuccess } from './notificacoesActions';

export const enviarMensagemRequest = createAction('ENVIAR_MENSAGEM_REQUEST');
export const enviarMensagemSuccess = createAction('ENVIAR_MENSAGEM_SUCCESS');
export const enviarMensagemError = createAction('ENVIAR_MENSAGEM_ERROR');

export const enviarMensagem = (form) => async (dispatch) => {
  try {
    dispatch(enviarMensagemRequest());
    const { data } = await mensagemApi.enviarMensagem(form);
    dispatch(displaySuccess('Mensagem enviada com sucesso!'));
    dispatch(enviarMensagemSuccess(data));
    return true;
  } catch (e) {
    console.log(e);
    dispatch(enviarMensagemError(errorHandler(e)));
    dispatch(displayError(errorHandler(e)));
    return false;
  }
};
