import React from "react";
import { Row } from "reactstrap";

import CheckIcon from "mdi-react/CheckCircleIcon";
import UncheckIcon from "mdi-react/CloseCircleIcon";

import styles from '../../scss/components/_validate-qrcode.module.scss';

import { getQrCode } from "../../redux/actions/associadoActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

const MensagemValidateQrCode = ({ ...args }) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const associado = useSelector((state) => state.associados.associado);
  const error = useSelector((state) => state.associados.error);

  useEffect(() => {
    dispatch(getQrCode(token));
  }, [dispatch, token]);

  return (
    <>
      {!error ? (
        <div className={styles.success}>
          <Row>
            <CheckIcon size={200} className="text-success surgir" />
          </Row>
          <Row>
            <h3>
              Declaramos que <strong>{associado?.nome}</strong> possui associação
              ATIVA junto à ASCESA, estando apto a usufruir dos benefícios junto
              aos estabelecimentos credenciados.
            </h3>
          </Row>
          <Row className="pt-5">
            <h3>
              Declaração válida até {<strong>{moment(associado?.qrCodeExpiry).utc(false).format('DD/MM/YYYY HH:mm')}</strong>}
            </h3>
          </Row>
        </div>
      ) : (
        <div className={styles.error}>
          <Row>
            <UncheckIcon size={200} className="text-danger surgir" />
          </Row>
          <Row>
            <h3>
              {error}
            </h3>
          </Row>
        </div>
      )}
    </>
  );
};

export default MensagemValidateQrCode;
