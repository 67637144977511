import React, { useReducer, useState } from 'react';
import { Col, Container, Row, Progress } from 'reactstrap';
import { DadosEnderecoTelefone } from './DadosEnderecoTelefone';
import { DadosFamiliaDependentes } from './DadosFamiliaDependentes';
import { DadosPessoais } from './DadosPessoais';
import { Multi, Step } from '../../../shared/Multi';
import { DadosLogin } from './DadosLogin';
import { Confirmacao } from './Confirmacao';
import ImgCadastroAcesa from '../../../shared/img/cadastro-ascesa.png';
import RodapeSection from '../../../components/RodapeSection';

export const FormAssocia = () => {
  const [userData, setUserData] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {}
  );

  const [valorProgress, setValorProgress] = useState(20);

  function progressBar(data) {
    setValorProgress((prev) => prev + data);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col xs={12} lg={6} className="container-associar-se">
            <img
              alt="img-associarSe"
              className="margin-input-from-img img-fluid mx-auto img-associarSe"
              src={ImgCadastroAcesa}
            />
          </Col>
          <Col>
            <Progress color="primary" value={valorProgress} />
            <Multi value={{ userData, setUserData, progressBar }}>
              <Step component={DadosPessoais} />
              <Step component={DadosEnderecoTelefone} />
              <Step component={DadosFamiliaDependentes} />
              <Step component={DadosLogin} />
              <Step component={Confirmacao} />
            </Multi>
          </Col>
        </Row>
      </Container>
      <div className="footer-margin"></div>
      <RodapeSection />
    </div>
  );
};
