import { createAction } from '@reduxjs/toolkit';
import usuarioApi from '../../utils/api/usuarioApi';
import { displayError } from './notificacoesActions';

export const getUsuarioRequest = createAction('GET_USUARIO_REQUEST');
export const getUsuarioSuccess = createAction('GET_USUARIO_SUCCESS');
export const getUsuarioError = createAction('GET_USUARIO_ERROR');

export const newUsuario = createAction('NEW_USUARIO');

export const getUsuario = () => async (dispatch, getStore) => {
  try {
    dispatch(getUsuarioRequest());
    const { data } = await usuarioApi.get(getStore().auth.UID);
    dispatch(getUsuarioSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(displayError(error));
    dispatch(getUsuarioError(error));
  }
};
