import React from 'react';

import { useNavigate, createSearchParams } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';

import BagSuitcaseIcon from 'mdi-react/BagSuitcaseIcon';
import SilverwareVariantIcon from 'mdi-react/SilverwareVariantIcon';
import SoccerIcon from 'mdi-react/SoccerIcon';
import ContentCutIcon from 'mdi-react/ContentCutIcon';
import BookOpenVariantIcon from 'mdi-react/BookOpenVariantIcon';
import PillIcon from 'mdi-react/PillIcon';
import CartPercentIcon from 'mdi-react/CartPercentIcon';
import PlusIcon from 'mdi-react/PlusIcon';

import { CategoriaConvenioEnum } from '../../utils/constantes';

const beneficios = [
  {
    icone: BagSuitcaseIcon,
    texto: 'viagens e hospedagem',
    categoria: CategoriaConvenioEnum.Turismo,
  },
  {
    icone: SilverwareVariantIcon,
    texto: 'restaurantes',
    categoria: CategoriaConvenioEnum.Comer_e_Beber,
  },
  {
    icone: SoccerIcon,
    texto: 'clubes, esportes e academias',
    categoria: CategoriaConvenioEnum.Saude_e_Esportes,
  },
  {
    icone: ContentCutIcon,
    texto: 'centros de beleza e barbearias',
    categoria: CategoriaConvenioEnum.Beleza_e_BemEstar,
  },
  {
    icone: BookOpenVariantIcon,
    texto: 'graduação, pós-graduação e idiomas',
    categoria: CategoriaConvenioEnum.Cursos,
  },
  {
    icone: PillIcon,
    texto: 'farmácias',
    categoria: CategoriaConvenioEnum.Farmácias,
  },
  {
    icone: CartPercentIcon,
    texto: 'descontos em lojas online',
    online: true,
  },
  {
    icone: PlusIcon,
    texto: 'e muito mais',
  },
];
const titulo = 'benefícios dos associados';

const Beneficios = ({ ...args }) => {
  const navigate = useNavigate();
  const handleNavigate =
    ({ categoria, online }) =>
    () => {
      const data = {};
      if (online !== undefined) data.online = +!!online;
      if (categoria) data.categoria = categoria;
      window.scrollTo(0, 0);
      navigate({
        pathname: '/pesquisar-convenios',
        search: createSearchParams(data).toString(),
      });
    };
  return (
    <section id="beneficios">
      <Container>
        <Row className="gap-5 gap-md-0">
          <Col xs={12} md={4} className="text-start d-flex align-items-center">
            <h2>{titulo}</h2>
          </Col>
          <Col xs={12} md={8}>
            <Container fluid>
              <Row>
                {beneficios.map(
                  ({ icone: Icone, categoria, texto, online }, key) => (
                    <Col xs={6} md={3} key={key}>
                      <div
                        className="beneficio-card"
                        role="button"
                        onClick={handleNavigate({ categoria, online })}
                      >
                        {Icone && <Icone className="beneficio-card__icone" />}
                        {texto}
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Beneficios;
