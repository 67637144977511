import { useEffect, useState } from 'react';

const useGeolocation = () => {
  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(false);
  const [location, setLocation] = useState(null);
  const geolocation = navigator.geolocation;

  const handleSuccess = (position) => {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    setLocation({ lat, lng });
    setError(null);
    setFetching(false);
  };
  const handleError = () => {
    setError('Não foi possível encontrar sua localização');
    setFetching(false);
  };

  useEffect(() => {
    setFetching(true);
    if (!geolocation) {
      setError('O navegador não possui suporte para Geolocalização');
      setFetching(false);
      return;
    }
    geolocation.getCurrentPosition(handleSuccess, handleError);
  }, [geolocation]);

  return {
    error,
    fetching,
    location,
  };
};

export default useGeolocation;
