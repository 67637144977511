import { HAS_LOCAL_STORAGE, HAS_SESSION_STORAGE, JWT_KEY } from './constantes';

export const getSession = () => {
  const session =
    sessionStorage.getItem(JWT_KEY) || localStorage.getItem(JWT_KEY);
  return session && JSON.parse(session);
};

export const destroySession = () => {
  sessionStorage.removeItem(JWT_KEY);
  localStorage.removeItem(JWT_KEY);
};

export const hasSession = () => {
  if (sessionStorage.getItem(JWT_KEY)) return HAS_SESSION_STORAGE;
  if (localStorage.getItem(JWT_KEY)) return HAS_LOCAL_STORAGE;
  return false;
};

export const createSession = ({ data, keep }) => {
  if (keep) {
    localStorage.setItem(JWT_KEY, JSON.stringify(data));
  } else {
    sessionStorage.setItem(JWT_KEY, JSON.stringify(data));
  }
};

export const getToken = () => {
  const session = getSession();

  if (session?.token) {
    return session.token;
  } else {
    return '';
  }
};

export const getUserId = () => {
  const session = getSession();
  if (session?._id) {
    return session._id;
  } else {
    return '';
  }
};
