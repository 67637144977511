import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import HandHeartIcon from 'mdi-react/HandHeartIcon';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';

const titulo = (
  <>
    Nosso <b>compromisso</b> juntos aos associados...
  </>
);

const lista = [
  {
    icone: HandHeartIcon,
    texto: 'promover e estreitar os sentimentos de solidariedade',
  },
  {
    icone: EmoticonIcon,
    texto:
      'organizar e promover permanentemente atividades para o aprimoramento cultural, social e esportivo dos associados',
  },
  {
    icone: CurrencyUsdIcon,
    texto:
      'proporcionar facilidades para aquisição de bens e serviços em condições mais favoráveis que as do mercado',
  },
];

const NossoCompromisso = ({ ...args }) => {
  return (
    <div className="bg-light my-5 py-5 px-3 px-md-5 ">
      <Container className="d-flex flex-column gap-3">
        <Row>
          <Col xs={12}>
            <h2 className="text-center text-md-start">{titulo}</h2>
          </Col>
        </Row>
        <Row>
          {lista.map((item, key) => (
            <Fragment key={key}>
              <Col xs={12} md={12 / lista.length}>
                <div className="p-5 d-flex align-items-center flex-column gap-3">
                  <item.icone size="5rem" />
                  <p className="text-center">{item.texto}</p>
                </div>
              </Col>
            </Fragment>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default NossoCompromisso;
