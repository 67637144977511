import { post, put } from './base';

const authApi = {
  signin: (form) => post('signin', form),
  signout: () => post('signout'),
  recuperarSenha: (email) => put('/forgot-password', { email }),
  resetarSenha: (newPassword, resetPasswordLink) => put('/reset-password', {newPassword, resetPasswordLink}),

  generateSimpleOtp: (form) => post('/generate-simple-otp', form),
  validateSimpleOtp: (form) => put('/validate-simple-otp', form),
};

export default authApi;
