import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import LogoutIcon from "mdi-react/LogoutIcon";
import Logo from "../../components/Logo";
import { toggleLoginModal } from "../../redux/actions/modalsActions";
import { signout } from "../../redux/actions/authActions";
import Hamburguer from "../../shared/components/Hamburguer";

const NavBar = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const usuario = useSelector((state) => state.usuario.usuario);
  const [abrir, setAbrir] = useState(false);
  const openLoginModal = () => {
    dispatch(toggleLoginModal());
  };
  const handleLogout = () => {
    dispatch(signout());
  };

  const handleAbrir = () => {
    setAbrir(!abrir);
  };

  return (
    <Navbar color="primary" expand="md" dark>
      <NavbarBrand className="ms-3" href="/">
        <Logo className="d-md-block" variant="white" />
      </NavbarBrand>
      <NavbarToggler onClick={handleAbrir}>
        <Hamburguer state={abrir} />
      </NavbarToggler>
      <Collapse className="custom-navbar-collapse" isOpen={abrir} navbar>
        <Nav navbar>
          <NavItem>
            <NavLink href="/">home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/institucional">institucional</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/pesquisar-convenios">pesquisar convênios</NavLink>
          </NavItem>
          {isAuth ? (
            <>
              <NavItem>
                {usuario.nome}
              </NavItem>
              <NavItem>
                <LogoutIcon
                  className="logout-icon"
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                />
              </NavItem>
            </>
          ) : (
            <>
              <NavItem>
                <NavLink href="/associe-se">associe-se</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={openLoginModal}>
                  entrar
                </NavLink>
              </NavItem>
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavBar;
