import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import MultiInput from '../../shared/components/MultiInput';
import {
  generateSimpleOtp,
  validateSimpleOtp,
} from '../../redux/actions/authActions';
import SimpleNotification from '../../components/SimpleNotification';

const validate = (otp) => {
  if (!otp || isNaN(+otp) || otp.length < 4) {
    return { otp: 'O código inserido é inválido' };
  }
  return {};
};

const OTP_RESEND_DELAY = 60;

const AssembleiaValidacaoOTP = ({ next }) => {
  const assembleia = useSelector((state) => state.assembleia.assembleia);
  const associado = useSelector((state) => state.usuario.usuario);
  const error = useSelector((state) => state.notificacoes.error);
  const OTP = useSelector((state) => state.otpReducer.OTP);
  const isFetching = useSelector((state) => state.otpReducer.isFetching);

  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(OTP_RESEND_DELAY);
  const [interval, setInterval_] = useState(null);

  const generateOtpAndCountdown = useCallback(async () => {
    const emailAssociado = associado.email;
    await dispatch(generateSimpleOtp({ email: emailAssociado }));
    const interval = setInterval(
      () =>
        setTimer((prev) => {
          if (prev <= 0) {
            clearInterval(interval);
          }
          return prev - 1;
        }),
      1000
    );
    setInterval_(interval);
  }, [associado, dispatch]);

  useEffect(() => {
    generateOtpAndCountdown();
  }, [generateOtpAndCountdown]);

  useEffect(() => () => clearInterval(interval), [interval]);

  const handleSubmit = async () => {
    const success = await dispatch(
      validateSimpleOtp({ otp, email: associado.email })
    );
    success && next();
  };

  const handleResend = (e) => {
    e.preventDefault();
    setTimer(OTP_RESEND_DELAY);
    generateOtpAndCountdown();
  };

  return (
    <>
      <section className="mx-5 my-4 text-center">
        <Col sm={12} md={6} className="offset-3">
          <h2 className="pb-5">
            Registro de voto na assembléia: {assembleia.nome}
          </h2>
          <p className="pb-5">{OTP}</p>
          {isFetching && (
            <div className="loading-overlay">
              <Spinner />
            </div>
          )}
          {!isFetching && (
            <Form
              onSubmit={handleSubmit}
              className="pb-5 d-flex justify-content-center"
              validate={() => validate(otp)}
            >
              {({ handleSubmit, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Field
                      className="font-size-big"
                      name="otp"
                      type="text"
                      amount={4}
                      component={MultiInput}
                      onChange={(value) => setOtp(value)}
                    />
                    {error === 'Código inválido.' && (
                      <SimpleNotification error={error} />
                    )}
                    <Button
                      className="mt-5"
                      color="primary"
                      disabled={isFetching || !!errors.otp}
                    >
                      Validar e Continuar
                    </Button>
                    {timer < 0 && (
                      <p className="fade-in">
                        Não recebeu o código?{' '}
                        <button
                          className="btn btn-link p-0"
                          onClick={handleResend}
                        >
                          Reenviar
                        </button>
                      </p>
                    )}
                  </form>
                );
              }}
            </Form>
          )}
        </Col>
      </section>
    </>
  );
};

export default AssembleiaValidacaoOTP;
