import React from 'react';
import { Provider } from 'react-redux';
import Router from './Router';
import store from './store';
import SessionManager from './SessionManager';
import GoogleApiManager from '../components/GoogleApiManager';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/style.scss';
import 'moment/locale/pt-br';

const App = () => {
  return (
    <Provider store={store}>
      <SessionManager>
        <GoogleApiManager>
          <Router />
        </GoogleApiManager>
      </SessionManager>
    </Provider>
  );
};
export default App;
