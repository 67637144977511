import { createAction } from '@reduxjs/toolkit';
import convenioApi from '../../utils/api/convenioApi';
import { errorHandler } from '../../utils/error';
// import { displayError } from './notificacoesActions';

export const genericConvenioRequest = createAction('GENERIC_CONVENIO_REQUEST');
export const genericConvenioSuccess = createAction('GENERIC_CONVENIO_SUCCESS');
export const genericConvenioError = createAction('GENERIC_CONVENIO_ERROR');

export const searchConveniosSuccess = createAction('SEARCH_CONVENIOS_SUCCESS');

export const fetchSearchConvenios =
  (query, page, pageSize) => async (dispatch, getState) => {
    dispatch(genericConvenioRequest());
    try {
      const { data } = await convenioApi.search(
        query,
        getState().auth.UID,
        page,
        pageSize
      );
      dispatch(genericConvenioSuccess());
      dispatch(searchConveniosSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(genericConvenioError(errorHandler(error)));
    }
  };
