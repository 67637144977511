import React, { useEffect } from 'react';
import styles from '../scss/components/_exclusao-usuario-form.module.scss';
import PropTypes from 'prop-types';
import clsx from 'classnames';

import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, Button, Spinner } from 'reactstrap';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';

import { enviarMensagem } from '../redux/actions/mensagemActions';
import SimpleNotification from './SimpleNotification';
import { clearAll } from '../redux/actions/notificacoesActions';

const title = 'Solicitar Exclusão de Dados';
const text =
  'Conforme disposto na Lei Geral de Proteção de dados, é direito do usuário solicitar a exclusão de seus cadastro e de todas as informações pessoais a ele associadas.';

const ExclusaoUsuarioForm = ({ logo, onFinish, toggle, className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const associado = useSelector((state) => state.usuario.usuario);
  const error = useSelector((state) => state.notificacoes.error);
  const isFetching = useSelector((state) => state.auth.isFetching);
  const success = useSelector((state) => state.notificacoes.success);

  console.log(associado);

  const handleSubmit = ({ email }) => {
    dispatch(clearAll());
    dispatch(
      enviarMensagem({
        assunto: 'Exclusão de Dados do Associado',
        mensagem: `
        Solicito por meio deste, a exclusão dos meus dados pessoais.
        Nome: ${associado.nome}
        Email: ${associado.email}
        CPF: ${associado.cpf}
        `,
      })
    );
  };

  const redirecionarHome = () => {
    toggle && toggle();
    navigate('/');
  };

  useEffect(() => () => dispatch(clearAll()), [dispatch]);

  return (
    <div className={clsx(styles['exclusao-usuario-form__wrapper'], className)}>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit, errors, touched }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className={clsx(
                styles['exclusao-usuario-form'],
                'container-fluid'
              )}
            >
              <Row>
                {logo && (
                  <Col xs={12} className="pb-2">
                    <Logo variant="color" size="big" />
                  </Col>
                )}
                <Col xs={12}>
                  <h2>{title}</h2>
                </Col>
                <Col xs={12} className="pt-3 pb-1 text-center">
                  <h5>{text}</h5>
                </Col>
                <Col xs={12}>
                  <SimpleNotification success={success} error={error} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-1">
                  {!success && (
                    <Button
                      color="primary"
                      disabled={isFetching}
                      className="px-5"
                    >
                      Solicitar
                    </Button>
                  )}
                  {success && (
                    <Button
                      // color="primary"
                      color="gray"
                      disabled={isFetching}
                      className="px-5"
                      onClick={redirecionarHome}
                    >
                      Voltar
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          );
        }}
      </Form>
      {isFetching && (
        <div
          className={clsx(styles['exclusao-usuario-form__loading'], {
            [styles['exclusao-usuario-form__success']]: true,
          })}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

ExclusaoUsuarioForm.propTypes = {
  logo: PropTypes.bool,
  toggle: PropTypes.func,
  onFinish: PropTypes.func,
  className: PropTypes.string,
};
ExclusaoUsuarioForm.defaultProps = {
  logo: true,
};

export default ExclusaoUsuarioForm;
