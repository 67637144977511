import React from 'react';
import { Container } from 'reactstrap';
import RodapeSection from '../../components/RodapeSection';

const titulo = 'Política de Privacidade';
const conteudo = (
  <div>
    <p>
      A ASCESA criou o aplicativo execlusivo para seus associados. Este SERVIÇO
      é fornecido pela ASCESA e destina-se a ser usado como está.
    </p>

    <p>
      Esta página é usada para informar os visitantes do site sobre nossas
      políticas com a coleta, uso e divulgação de Informações Pessoais se alguém
      decidiu usar nosso Serviço.
    </p>

    <p>
      Se você optar por usar nosso Serviço, você concorda com a coleta e uso de
      informações em relação a esta política. As Informações Pessoais que
      coletamos são usadas para fornecer e melhorar o Serviço. Não usaremos ou
      compartilharemos suas informações com ninguém, exceto conforme descrito
      nesta Política de Privacidade.
    </p>

    <p>
      Os termos utilizados nesta Política de Privacidade têm os mesmos
      significados que nos nossos Termos e Condições, que estão acessíveis da
      plataforma ASCESA, a menos que seja definido de outra forma nesta Política
      de Privacidade.
    </p>
    <br />
    <h3>Coleta e uso de informações</h3>
    <p>
      Para uma melhor experiência, ao usar nosso Serviço, podemos exigir que
      você nos forneça certas informações pessoalmente identificáveis. A
      informação que solicitamos é será mantida por nós e usada conforme
      descrito nesta política de privacidade.
    </p>
    <p>
      O aplicativo usa serviços de terceiros que podem coletar informações
      usadas para identificá-lo.
    </p>
    <br />
    <h3>Dados de log</h3>
    <p>
      Queremos informá-lo de que sempre que você usa o nosso Serviço, em caso de
      erro no aplicativo, coletamos dados e informações (por meio de produtos de
      terceiros) em seu telefone chamado Dados de Log. Esses dados podem incluir
      informações como o endereço do seu dispositivo (IP), o nome do
      dispositivo, a versão do sistema operacional, a configuração do aplicativo
      quando utilizar o nosso Serviço, a hora e a data do uso do Serviço e
      outras estatísticas.
    </p>
    <br />
    <h3>Cookies</h3>
    <p>Não coletamos cookies.</p>
    <br />
    <h3>Provedores de serviço</h3>
    <p>
      Podemos empregar empresas terceirizadas e pessoas físicas devido às
      seguintes razões:
    </p>
    <ul>
      <li>Para facilitar o nosso Serviço;</li>
      <li>Para prestar o Serviço em nosso nome;</li>
      <li>Para executar serviços relacionados ao serviço; ou</li>
      <li>Para nos ajudar a analisar como nosso Serviço é usado.</li>
    </ul>

    <p>
      Queremos informar os usuários deste Serviço que estes terceiros têm acesso
      às suas Informações Pessoais. A razão é executar as tarefas que lhes são
      atribuídas em nosso nome. No entanto, eles são obrigados a não divulgar ou
      usar a informação para qualquer outra finalidade.
    </p>
    <br />
    <h3>Segurança</h3>
    <p>
      Nós valorizamos sua confiança em nos fornecer suas Informações Pessoais,
      portanto estamos nos esforçando para usar meios comercialmente aceitáveis
      ​​para protegê-la. Mas lembre-se de que nenhum método de transmissão
      através da internet, ou método de armazenamento eletrônico é 100% seguro e
      confiável, e não podemos garantir sua segurança absoluta.
    </p>
    <br />
    <h3>Links para outros sites</h3>

    <p>Este serviço não contém links para outros sites.</p>
    <br />
    <h3>Alterações nesta Política de Privacidade</h3>

    <p>
      Podemos atualizar nossa Política de Privacidade de tempos em tempos.
      Assim, você é aconselhável revisar esta página periodicamente para
      qualquer alteração. Nós notificaremos você de quaisquer alterações
      publicando a nova Política de Privacidade nesta página. Essas alterações
      são efetivas imediatamente após serem publicadas nesta página.
    </p>
    <br />
    <h3>Contate-nos</h3>

    <p>
      Se você tiver dúvidas ou sugestões sobre nossa Política de Privacidade,
      não hesite em contatar-nos.;
    </p>
  </div>
);

const Privacidade = () => {
  return (
    <>
      <Container className="page-titulo-container">
        <h1>{titulo}</h1>
        <div>{conteudo}</div>
      </Container>
      <RodapeSection />
    </>
  );
};

export default Privacidade;
