import { handleActions } from 'redux-actions';
import {
  displayErrorRequest,
  clearErrorRequest,
  displaySuccessRequest,
  clearSuccessRequest,
} from '../actions/notificacoesActions';

const defaultState = {
  error: null,
  success: null,
};

export default handleActions(
  {
    [displayErrorRequest](state, { payload }) {
      return {
        ...state,
        error: payload,
      };
    },
    [clearErrorRequest](state, { payload }) {
      return {
        ...state,
        error: null,
      };
    },
    [displaySuccessRequest](state, { payload }) {
      return {
        ...state,
        success: (payload instanceof Object && Object.values(payload).at(0)) || payload?.toString(),
      };
    },
    [clearSuccessRequest](state, { payload }) {
      return {
        ...state,
        success: null,
      };
    },
  },
  defaultState
);
