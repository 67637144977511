import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import LoginForm from '../components/LoginForm';

const Login = ({ ...args }) => {
  const navigate = useNavigate();
  const handleFinish = () => {
    navigate(-1);
  };

  return (
    <Container className="d-flex justify-content-center pt-5">
      <LoginForm logo={false} onFinish={handleFinish} />
    </Container>
  );
};

export default Login;
