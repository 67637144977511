import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { hasSession } from '../utils/session';
import { fetchLocalData, signout } from '../redux/actions/authActions';
import { getUsuario } from '../redux/actions/usuarioActions';

const SessionManager = ({ children }) => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const UID = useSelector((state) => state.auth.UID);

  useEffect(() => {
    if (hasSession()) {
      if (isAuth === null) {
        try {
          dispatch(fetchLocalData());
        } catch (e) {
          console.error(e);
          dispatch(signout());
        }
      }
      return;
    }
    if (isAuth === null) {
      dispatch(signout());
    }
    return;
  }, [isAuth, dispatch]);

  useEffect(() => {
    UID && dispatch(getUsuario(UID)).catch(() => dispatch(signout()));
  }, [UID, dispatch]);

  return children;
};

export default SessionManager;
