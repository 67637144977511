import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * Impede usuarios NÃO autenticados de acessarem certas rotas
 * ex: Perfil
 */
const PrivateGuard = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

export default PrivateGuard;
