import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import { useSelector } from 'react-redux';

const AssembleiaObrigado = () => {
  const assembleia = useSelector((state) => state.assembleia.assembleia);
  const associado = useSelector((state) => state.usuario.usuario);

  const navigate = useNavigate();

  return (
    <>
      <section className="mx-5 mt-4 text-center">
        <Col sm={12} md={6} className="offset-3">
          <h2 className="pb-5">
            Registro de voto na assembléia: {assembleia.nome}
          </h2>
          <p className="pb-5">
            Sr(a). <strong>{associado.nome}</strong>,
          </p>
          <p className="pb-5">
            Seu voto foi registrado com sucesso. Muito obrigado!
          </p>

          <Button
            color="primary"
            onClick={() => navigate('/')}
            className="mt-5"
          >
            Encerrar
          </Button>
        </Col>
      </section>
    </>
  );
};

export default AssembleiaObrigado;
