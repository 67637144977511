import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import CustomCard from '../../components/CustomCard';
import ConvenioPesquisa from '../../components/ConvenioPesquisa';

const titulo = 'pesquisa de credenciados';
const texto =
  'nossa parceria com a plataforma Allya oferece benefícios e vantagens em mais de 27mil estabelecimentos em todo o Brasil:';

const cards = [
  {
    imagem:
      'https://i0.wp.com/cloud.estacaonerd.com/wp-content/uploads/2020/03/16163602/cinemark.jpg?fit=940%2C385&ssl=1',
    titulo: 'cinemark',
    texto:
      'Cinemark é uma das três maiores redes de cinema do mundo. É uma empresa transnacional especializada em operar complexos cinematográficos multiplex.',
    link: `${process.env.PUBLIC_URL}/pesquisar-convenios?nome=cinemark`,
  },
  {
    imagem:
      'https://www.cidademarketing.com.br/marketing/wp-content/uploads/2021/01/netshoes_marca.jpg',
    titulo: 'netshoes',
    texto:
      'Netshoes é uma empresa brasileira de comércio eletrônico de artigos esportivos fundada em fevereiro de 2000 por Marcio Kumruian e Hagop Chabab e adquirido pela Magazine Luiza em 2019.',
    link: `${process.env.PUBLIC_URL}/pesquisar-convenios?nome=netshoes`,
  },
];

const PesquisaCredenciados = ({ ...args }) => {
  const navigate = useNavigate();

  const handleNavigate = (url) => () => {
    window.scrollTo(0, 0);
    navigate(url);
  };

  return (
    <section id="pesquisa-credenciados">
      <Container>
        <Row>
          <Col md={6}>
            <Container fluid className="px-0 mx-0">
              <Row>
                {cards.map(({ imagem, titulo, texto, link }, i) => (
                  <Col key={i} md={6}>
                    <CustomCard
                      imagem={imagem}
                      titulo={titulo}
                      texto={texto}
                      action={handleNavigate(link)}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
          <Col
            md={{ offset: 1, size: 5 }}
            className="pe-5 d-flex justify-content-center flex-column"
          >
            <h2>{titulo}</h2>
            <p>{texto}</p>
          </Col>
        </Row>
        <Row className="my-4">
          <Col xs={12} className="d-flex align-items-center">
            <ConvenioPesquisa simple />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PesquisaCredenciados;
