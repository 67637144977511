import { handleActions } from "redux-actions";

import {
  createAssociarSeRequest,
  createAssociarSeSuccess,
  createAssociarSeError,
  getQrCodeRequest,
  getQrCodeSuccess,
  getQrCodeError,
  newAssociadoRequest,
} from "../actions/associadoActions";

const defaultState = {
  associados: [],
  totalPages: 0,
  associado: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [createAssociarSeRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createAssociarSeSuccess](state, { payload }) {
      return {
        ...state,
        associado: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createAssociarSeError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newAssociadoRequest](state, { payload }) {
      return {
        ...state,
        associado: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
    [getQrCodeRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getQrCodeSuccess](state, { payload }) {
      return { ...state, isFetching: false, associado: payload };
    },
    [getQrCodeError](state, { payload }) {
      return { ...state, isFetching: false, error: payload };
    },
  },
  defaultState
);
