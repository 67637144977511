import React, { useCallback, useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { Status } from '@googlemaps/react-wrapper';
import useGoogleApi from '../hooks/useGoogleApi';

const autocompleteProps = {
  componentRestrictions: { country: 'br' },
  fields: ['geometry'],
  strictBounds: true,
};

const GoogleLocationSearch = ({ onChoice, onChange, ...props }) => {
  const status = useGoogleApi('places');
  const inputRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState();
  const handlePlaceChange = useCallback(() => {
    const place = autocomplete.getPlace();
    const location = place
      ? {
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
        }
      : null;
    onChoice && onChoice(location);
  }, [onChoice, autocomplete]);

  const current = inputRef.current;
  useEffect(() => {
    if (current && !autocomplete && status === Status.SUCCESS) {
      setAutocomplete(
        new window.google.maps.places.Autocomplete(
          inputRef.current,
          autocompleteProps
        )
      );
    }
  }, [current, autocomplete, status]);

  useEffect(() => {
    if (!autocomplete) return;
    autocomplete.addListener('place_changed', handlePlaceChange);
  }, [autocomplete, handlePlaceChange]);

  return <input {...props} ref={inputRef} type="text" onChange={onChange} />;
};

GoogleLocationSearch.propTypes = {};
GoogleLocationSearch.defaultProps = {};

export default GoogleLocationSearch;
