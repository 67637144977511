import { createAction } from '@reduxjs/toolkit';
import authApi from '../../utils/api/authApi';
import { errorHandler } from '../../utils/error';
import { createSession, destroySession, getSession } from '../../utils/session';
import { displayError, displaySuccess } from './notificacoesActions';
import { newUsuario } from './usuarioActions';

export const signinRequest = createAction('SIGNIN_REQUEST');
export const signinSuccess = createAction('SIGNIN_SUCCESS');
export const signinError = createAction('SIGNIN_ERROR');

export const signoutRequest = createAction('SIGNOUT_REQUEST');
export const signoutSuccess = createAction('SIGNOUT_SUCCESS');
export const signoutError = createAction('SIGNOUT_ERROR');

export const recuperarSenhaRequest = createAction('RECUPERAR_SENHA_REQUEST');
export const recuperarSenhaSuccess = createAction('RECUPERAR_SENHA_SUCCESS');
export const recuperarSenhaError = createAction('RECUPERAR_SENHA_ERROR');

export const resetarSenhaRequest = createAction('RESETAR_SENHA_REQUEST');
export const resetarSenhaSuccess = createAction('RESETAR_SENHA_SUCCESS');
export const resetarSenhaError = createAction('RESETAR_SENHA_ERROR');

export const fetchLocalDataAction = createAction('FETCH_LOCAL_DATA');

export const generateSimpleOtpRequest = createAction('GERENERATE_OTP_REQUEST');
export const generateSimpleOtpSuccess = createAction('GERENERATE_OTP_SUCCESS');
export const generateSimpleOtpError = createAction('GERENERATE_OTP_ERROR');

export const validateSimpleOtpRequest = createAction('VALIDATE_OTP_REQUEST');
export const validateSimpleOtpSuccess = createAction('VALIDATE_OTP_SUCCES');
export const validateSimpleOtpError = createAction('VALIDATE_OTP_ERROR');

export const signin =
  ({ email, password, keep }) =>
  async (dispatch) => {
    try {
      destroySession();
      dispatch(signinRequest());
      const { data } = await authApi.signin({ email, password });
      createSession({
        data: { token: data.token, _id: data.associado._id },
        keep,
      });
      dispatch(signinSuccess(data.associado));
      dispatch(displaySuccess('Sucesso ao efetuar Login!'));
    } catch (error) {
      console.error(error);
      dispatch(displayError(error));
      dispatch(signinError(error));
    }
  };

export const signout = () => async (dispatch) => {
  try {
    dispatch(signoutRequest());
    await authApi.signout();
    dispatch(signoutSuccess());
    dispatch(newUsuario());
  } catch (error) {
    console.error(error);
    dispatch(signoutError(error));
  } finally {
    destroySession();
  }
};

export const recuperarSenha = (email) => async (dispatch) => {
  try {
    dispatch(recuperarSenhaRequest());
    const { data } = await authApi.recuperarSenha(email);
    dispatch(displaySuccess("Email enviado com sucesso"));
    dispatch(recuperarSenhaSuccess(data));
    return data;
  } catch (e) {
    console.error('recuperação', e);
    dispatch(displayError(e));
    dispatch(recuperarSenhaError(e));
  }
};

export const resetarSenha =
  (senha, token) => async (dispatch) => {
    try {
      dispatch(resetarSenhaRequest());
      const { data } = await authApi.resetarSenha(
        senha,
        token
      );

      await dispatch(displaySuccess(data));
      await dispatch(resetarSenhaSuccess(data));

      return data;
    } catch (e) {
      console.log('reset', e);
      dispatch(displayError(e));
      await dispatch(resetarSenhaError(e));
    }
  };

export const fetchLocalData = () => (dispatch) => {
  const session = getSession();
  if (!session || !session._id || !session.token)
    throw Error('Sessão Inválida');
  dispatch(fetchLocalDataAction(session));
};

export const generateSimpleOtp = (email) => async (dispatch) => {
  try {
    dispatch(generateSimpleOtpRequest());
    const { data } = await authApi.generateSimpleOtp(email);
    dispatch(displaySuccess('Sucesso ao gerar uma requisição OTP!'));
    dispatch(generateSimpleOtpSuccess(data));
    return true;
  } catch (e) {
    console.log(e);
    dispatch(generateSimpleOtpError(e));
    dispatch(displayError(e));
    return false;
  }
};

export const validateSimpleOtp =
  ({ email, otp }) =>
  async (dispatch) => {
    try {
      dispatch(validateSimpleOtpRequest());
      const { data } = await authApi.validateSimpleOtp({ email, otp });
      dispatch(validateSimpleOtpSuccess(data));
      dispatch(displaySuccess('Sucesso ao validar requisição OTP!'));
      return true;
    } catch (e) {
      console.error(e);
      dispatch(validateSimpleOtpError(errorHandler(e)));
      dispatch(displayError(errorHandler(e)));
      return false;
    }
  };
