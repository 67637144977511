import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import RecuperarSenhaForm from '../components/RecuperarSenhaForm';

const RecuperarSenha = ({ ...args }) => {
  const navigate = useNavigate();
  const handleFinish = () => {
    navigate(-1);
  };

  return (
    <Container className="d-flex justify-content-center pt-5">
      <RecuperarSenhaForm logo={false} onFinish={handleFinish} />
    </Container>
  );
};

export default RecuperarSenha;
