import React from 'react';
import { Button, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import SimpleNotification from '../../components/SimpleNotification';
import { signout } from '../../redux/actions/authActions';

const AssembleiaInicio = ({ next }) => {
  const associado = useSelector((state) => state.usuario.usuario);
  const error = useSelector((state) => state.otpReducer.error);

  const assembleia = useSelector((state) => state.assembleia.assembleia);
  const dispatch = useDispatch();

  const handleBtnIniciar = () => {
    next();
  };

  const handleLogout = () => {
    dispatch(signout());
  };

  return (
    <>
      <section className="mx-5 mt-4 text-center">
        <Col sm={12} md={6} className="">
          <h2>Registro de voto na assembléia: {assembleia.nome}</h2>
          <SimpleNotification error={error} className="mb-5" />
          <p className="pb-5">
            Você está logado como: <strong>{associado.nome}</strong>. Caso não
            seja você, será necessário{' '}
            <button className="no-style-button" onClick={handleLogout}>
              <span className="span-name">sair</span>
            </button>{' '}
            do sistema e efetuar novo{' '}
            <button className="no-style-button">
              <span className="span-name">login</span>
            </button>
          </p>

          <Button color="primary" onClick={handleBtnIniciar} disabled={error}>
            Iniciar
          </Button>
        </Col>
      </section>
    </>
  );
};

export default AssembleiaInicio;
