import { createReducer } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error';
import * as actions from '../actions/authActions';

const initialState = {
  isFetching: false,
  error: null,
  OTP: null,
  isValid: undefined,
};

const otpReducer = createReducer(initialState, {
  [actions.generateSimpleOtpRequest]: (state) => ({
    ...state,
    isValid: undefined,
    OTP: undefined,
    error: null,
    isFetching: true,
  }),
  [actions.generateSimpleOtpSuccess]: (state, { payload }) => ({
    ...state,
    OTP: payload.message,
    isFetching: false,
    error: null,
  }),
  [actions.generateSimpleOtpError]: (state, { payload }) => ({
    ...state,
    OTP: undefined,
    isFetching: false,
    error: errorHandler(payload.message),
  }),
  [actions.validateSimpleOtpRequest]: (state, { payload }) => ({
    ...state,
    isValid: undefined,
    OTP: undefined,
    isFetching: true,
    error: null,
  }),
  [actions.validateSimpleOtpSuccess]: (state, { payload }) => ({
    ...state,
    isValid: true,
    isFetching: false,
    error: null,
  }),
  [actions.validateSimpleOtpError]: (state, { payload }) => ({
    ...state,
    isValid: false,
    isFetching: false,
    error: errorHandler(payload.message),
  }),
});

export default otpReducer;
