import React, { useEffect } from "react";
import styles from "../../scss/components/_login-form.module.scss";
import PropTypes from "prop-types";
import clsx from "classnames";

import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Button, Spinner } from "reactstrap";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import CheckBoldIcon from "mdi-react/CheckBoldIcon";
import Logo from "../Logo";

import { signin } from "../../redux/actions/authActions";
import validate from "./validate";

/* Um abraço pra meu amigo... Ceguinho */
const LoginForm = ({ logo, onFinish, toggle, className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFetching = useSelector((state) => state.auth.isFetching);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const error = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (!isAuth || !onFinish) return;
    let timeout = setTimeout(() => {
      onFinish();
    }, 500);
    return () => clearTimeout(timeout);
  }, [isAuth, onFinish]);

  const handleSubmit = (values) => {
    const data = {
      ...values,
      keep: !!values.keep,
    };
    dispatch(signin(data));
  };

  const redirecionarCadastro = () => {
    toggle && toggle();
    navigate("/associe-se");
  };

  const redirecionarSenhaNova = () => {
    toggle && toggle();
    navigate("/recuperar-senha");
  };

  return (
    <div className={clsx(styles["login-form__wrapper"], className)}>
      <Form onSubmit={handleSubmit} validate={validate}>
        {({ handleSubmit, errors, touched }) => {
          const emailError =
            errors["login"] && touched["login"] ? "error" : undefined;
          const senhaError =
            errors["password"] && touched["password"] ? "error" : undefined;
          return (
            <form
              onSubmit={handleSubmit}
              className={clsx(styles["login-form"], "container-fluid")}
            >
              <Row>
                {logo && (
                  <Col xs={12} className="pb-2">
                    <Logo variant="color" size="big" />
                  </Col>
                )}
                <Col xs={12}>
                  <h2>Já é associado?</h2>
                </Col>

                <Col xs={12}>
                  <div className={styles["login-form__error"]}>{error}</div>
                </Col>
              </Row>
              <Row xs={12} className={(styles["login-form__content"], "gap-0")}>
                <Row xs={12} className={(styles["login-form__content"], "gap-0")}>
                  <Col xs={12} className="pb-2 p-0">
                    <Field
                      type="text"
                      component="input"
                      name="email"
                      placeholder="E-mail"
                      className="input-border w-100"
                      error={emailError}
                    />
                  </Col>
                  <Col xs={12} className="p-0">
                    <Field
                      component="input"
                      name="password"
                      placeholder="Senha"
                      className="input-border w-100"
                      type="password"
                      error={senhaError}
                    />
                  </Col>
                  <Col xs={12} className="p-0">
                    <div className="d-flex justify-content-start w-75">
                      <Field
                        component="input"
                        type="checkbox"
                        name="keep"
                        id="formCheckboxKeep"
                        className="me-2"
                      />
                      <label className="fw-normal" htmlFor="formCheckboxKeep">
                        Lembrar de mim
                      </label>
                    </div>
                    <div className="d-flex justify-content-end w-75">
                      <button
                        className="btn btn-link font-size-small p-0"
                        onClick={redirecionarSenhaNova}
                        type="button"
                      >
                        Esqueceu sua senha?
                      </button>
                    </div>
                  </Col>
                </Row>
              </Row>
              <Row>
                <Col xs={12} className="mb-3"></Col>
                <Col xs={12} className="mb-1">
                  <Button
                    color="primary"
                    disabled={isFetching || isAuth}
                    className="px-5"
                  >
                    {isAuth
                      ? "Autenticado"
                      : isFetching
                      ? "Autenticando"
                      : "Entrar"}
                  </Button>
                </Col>
                <Col xs={12}>
                  <button
                    className="btn btn-link font-size-medium"
                    onClick={redirecionarCadastro}
                    type="button"
                  >
                    Associar-se agora
                  </button>
                </Col>
              </Row>
            </form>
          );
        }}
      </Form>
      {(isFetching || isAuth) && (
        <div
          className={clsx(styles["login-form__loading"], {
            [styles["login-form__success"]]: isAuth,
          })}
        >
          {isAuth ? <CheckBoldIcon /> : <Spinner />}
        </div>
      )}
    </div>
  );
};

/* E pra esposa dele */

LoginForm.propTypes = {
  logo: PropTypes.bool,
  toggle: PropTypes.func,
  onFinish: PropTypes.func,
  className: PropTypes.string,
};
LoginForm.defaultProps = {
  logo: true,
};

export default LoginForm;
