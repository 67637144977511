import React from 'react';
import { Container, Row, Col, Button, Card } from 'reactstrap';
import DownloadIcon from 'mdi-react/DownloadIcon';

const Estatuto = ({ ...args }) => {
  return (
    <Container className="mb-5">
      <Row className="d-flex justify-content-center">
        <Col xs={12} md={8}>
          <Card
            body
            className="bg-light border-0 d-flex gap-3 flex-column p-5 h-100 justify-content-between"
          >
            <div>
              <h2>estatuto</h2>
              <p>Acesse a versão do estatuto da ascesa</p>
            </div>
            <Button color="gray">
              <a
                href={`${process.env.PUBLIC_URL}/files/estatuto-da-ascesa.pdf`}
                target="_blank"
                rel="noreferrer"
                className="text-reset text-decoration-none"
              >
                <div className="d-flex gap-2 justify-content-center">
                  <DownloadIcon />
                  <span>download</span>
                </div>
              </a>
            </Button>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Estatuto;
