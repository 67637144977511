import React from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createVoto } from '../../redux/actions/votoActions';
import SimpleNotification from '../../components/SimpleNotification';

const AssembleiaConfirmacao = ({ userData, next, prev }) => {
  const assembleia = useSelector((state) => state.assembleia.assembleia);
  const chapas = assembleia.chapas;
  const isFetching = useSelector((state) => state.assembleia.isFetching);
  const associado = useSelector((state) => state.usuario.usuario);
  const error = useSelector((state) => state.voto.error);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const idAssociado = associado._id;
    const idChapa = userData._id;
    const id = assembleia._id;
    const data = { ...userData };
    const success = await dispatch(createVoto(data, id, idChapa, idAssociado));
    success && next();
  };

  return (
    <>
      <section className="mx-5 mt-4 text-center">
        <Col sm={12} md={6} className="offset-3">
          <h2 className="pb-5">
            Registro de voto na assembléia: {assembleia.nome}
          </h2>
          <p className="pb-5">
            Sr(a). <strong>{associado.nome}</strong>, confirme a sua opção, por
            favor:
          </p>

          {isFetching && <Spinner />}
          {chapas && !isFetching && (
            <>
              {chapas?.map((item) => (
                <Button
                  key={item._id}
                  block
                  style={{
                    '--cor': item.cor.hex,
                  }}
                  disabled={item._id !== userData._id}
                  className="my-2 btn-assembleia-voto"
                >
                  {item.nome}
                </Button>
              ))}
            </>
          )}
          {error && (
            <Row>
              <Col md={12}>
                {<SimpleNotification error={error} className="" />}
              </Col>
            </Row>
          )}
          <Button color="gray" onClick={prev} className="mt-5 mx-4">
            Voltar
          </Button>
          <Button color="primary" onClick={handleSubmit} className="mt-5 mx-4">
            Confirmar
          </Button>
        </Col>
      </section>
    </>
  );
};

export default AssembleiaConfirmacao;
