import { createAction } from '@reduxjs/toolkit';
import assembleiaApi from '../../utils/api/assembleiaApi';
import { displayError } from './notificacoesActions';

export const getAssembleRequest = createAction('GET_ASSEMBLEIA_REQUEST');
export const getAssembleSuccess = createAction('GET_ASSEMBLEIA_SUCCESS');
export const getAssembleError = createAction('GET_ASSEMBLEIA_ERROR');

export const newAssembleia = createAction('NEW_ASSEMBLEIA');

export const getAssembleia = (id) => async (dispatch) => {
  try {
    dispatch(getAssembleRequest());
    const { data } = await assembleiaApi.getAssembleia(id);
    dispatch(getAssembleSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getAssembleError(e));
    dispatch(displayError(e));
  }
};
