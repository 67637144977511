import { cpf } from 'cpf-cnpj-validator';

export const validatePessoais = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo Nome é obrigatório';
  }
  if (!values.cpf) {
    errors.cpf = 'O campo CPF é obrigatório';
  }
  if (values.cpf && !cpf.isValid(values.cpf)) {
    errors.cpf = 'CPF inválido';
  }
  if (!values.rg) {
    errors.rg = 'O campo RG é obrigatório';
  }
  if (!values.dataNascimento) {
    errors.dataNascimento = 'O campo data de Nascimento é obrigatório';
  }
  if (!values.sexo) {
    errors.sexo = 'O campo sexo é obrigatório';
  }
  if (!values.estadoCivil) {
    errors.estadoCivil = 'O campo Estado Civil é obrigatório';
  }
  if (!values.empresaVinculo) {
    errors.empresaVinculo = 'O campo Empresa na qual Trabalha é obrigatório';
  }
  return errors;
};

export const validateTelEndereco = (values) => {
  const errors = {};
  if (!values.cep) {
    errors.cep = 'O campo CEP é obrigatório';
  }
  if (!values.endereco) {
    errors.endereco = 'O campo endereço é obrigatório';
  }
  if (!values.telCelular) {
    errors.telCelular = 'O campo Telefone Celular é obrigatório';
  }
  if (!values.nomePai) {
    errors.nomePai = 'O campo nome do Pai é obrigatório';
  }
  if (!values.nomeMae) {
    errors.nomeMae = 'O campo nome da Mãe é obrigatório';
  }
  return errors;
};

export const validateDependente = (values) => {
  const erros = {};
  if (!values?.nome) {
    erros.nome = 'O campo Nome é obrigatório';
  }
  if (!values?.dataNascimento) {
    erros.dataNascimento = 'O campo Data de Nascimento é obrigatório';
  }
  if (values?.cpf && !cpf.isValid(values.cpf)) {
    erros.cpf = 'CPF inválido';
  }
  if (!values?.sexo) {
    erros.sexo = 'O campo sexo é obrigatório';
  }
  return erros;
};

export const validateDadosLogin = (values) => {
  const errors = {};
  if (!values.confirmVeracidade) {
    errors.confirmVeracidade = 'Você deve confirmar a veracidade';
  }
  if (!values.email) {
    errors.email = 'O campo email é obrigatório';
  }
  if (!values.password) {
    errors.password = 'O campo senha é obrigatório';
  }
  if (!values.passwordConf) {
    errors.passwordConf = 'O campo de confirmar a Senha é obrigatório';
  }
  if (values.passwordConf !== values.password) {
    errors.passwordConf = 'O campo de Confirmação e Senha devem ser iguais';
  }
  return errors;
};
