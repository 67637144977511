import { Container, Row } from "reactstrap";
import MensagemValidateQrCode from "../../components/MensagemValidateQrCode/MensagemValidateQrCode";
import RodapeSection from "../../components/RodapeSection";

const ValidateQrCode = ({}) => {
  return (
    <div className="d-grid gap-9">
      <Container className="d-flex justify-content-center p-5">
        <MensagemValidateQrCode />
      </Container>
      <Row className="p-5"></Row>
      <RodapeSection/>
    </div>
  );
};

export default ValidateQrCode;
