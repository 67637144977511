import React, { createContext, useContext, useEffect, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const GoogleContext = createContext();

const StatusComponent = ({ status }) => {
  const { setStatus } = useContext(GoogleContext);

  useEffect(() => {
    setStatus(status);
  }, [status, setStatus]);
  return <></>;
};

const handleRender = (status) => {
  return <StatusComponent status={status} />;
};

const GoogleApiManager = ({ children }) => {
  const [libs, setLibs] = useState(['places']);
  const [status, setStatus] = useState(null);
  const [using, setUsing] = useState([]);

  const register = (id) => {
    setUsing((prev) => (id ? [...prev, id] : prev));
  };
  const unregister = (id) => {
    setUsing((prev) => {
      const i = prev.indexOf(id);
      if (i === -1) return prev;
      const newArray = Array.from(prev);
      newArray.splice(i, 1);
      return newArray;
    });
  };

  const addLibrary = (lib) => {
    setLibs((prev) => [...new Set([...prev, lib])]);
  };

  return (
    <GoogleContext.Provider
      value={{ register, unregister, status, addLibrary, setStatus }}
    >
      {using.length > 0 && (
        <Wrapper apiKey={apiKey} libraries={libs} render={handleRender} />
      )}
      {children}
    </GoogleContext.Provider>
  );
};

export default GoogleApiManager;
