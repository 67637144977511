import React from "react";
import PropTypes from "prop-types";
import styles from "../scss/components/_convenio-card.module.scss";

import { useDispatch } from "react-redux";
import { Card, CardBody, CardImg, CardTitle } from "reactstrap";
import StarIcon from "mdi-react/StarIcon";
import { parseDistanceKm } from "../utils/helpers";
import { toggleLoginModal } from "../redux/actions/modalsActions";

const placeholder = require("../images/placeholder.jpg");

const ConvenioCard = ({ convenio }) => {
  const dispatch = useDispatch();

  const handleLink = (e) => {
    if (convenio.link) return;
    e.preventDefault();
    dispatch(toggleLoginModal());
  };

  return (
    <Card className={styles["convenio-card"]}>
      <a
        href={convenio.link || "#"}
        onClick={handleLink}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles["card-img"]}>
          <CardImg
            src={convenio?.image?.logo || placeholder}
            alt={`Logotipo ${convenio.name}`}
          />
        </div>
      </a>
      <CardBody className={styles["card-body"]}>
        <CardTitle>
          <a
            href={convenio.link || "#"}
            onClick={handleLink}
            target="_blank"
            rel="noreferrer"
          >
            <h3 className={styles.title}>{convenio.name}</h3>
          </a>
        </CardTitle>

        <div className={styles.content}>
          <div className="mb-2">
            <h4>{convenio.offer?.title}</h4>
          </div>
          <div>
            <p>
              {`${convenio.addressess[0].county} (${parseDistanceKm(
                convenio.addressess[0].distance
              )})`}
            </p>
            <p>{convenio.addressess[0].nameUnit}</p>
            <p>{convenio.addressess[0].street}</p>
          </div>
        </div>
        <footer className={styles.footer}>
          <StarIcon className="text-primary" />
          <span>{convenio.rating}</span>
        </footer>
      </CardBody>
    </Card>
  );
};

ConvenioCard.propTypes = {
  convenio: PropTypes.object.isRequired,
};
ConvenioCard.defaultProps = {};

export default ConvenioCard;
