import React from 'react';
import { Button } from 'reactstrap';
import Dot3 from 'react-dotdotdot';
import styles from '../scss/components/_custom-card.module.scss';
import clsx from 'classnames';

const CustomCard = ({ imagem, titulo, texto, action, wholeCardAction }) => {
  return (
    <div
      className={clsx('card', styles['card'], styles['card-custom'])}
      role={wholeCardAction ? 'button' : undefined}
      onClick={wholeCardAction ? action : undefined}
    >
      <div
        className={styles['card-img-wrapper']}
        style={{ backgroundImage: `url(${imagem})` }}
      ></div>
      <div className={clsx('card-body', styles['card-body'])}>
        <div className={styles['card-text']}>
          <h3 className={clsx('card-title', styles['card-title'])}>
            <Dot3 clamp={2}>{titulo}</Dot3>
          </h3>
          <div className={styles['card-content']}>
            <Dot3 clamp={2}>{texto}</Dot3>
          </div>
        </div>
        <div className={styles['card-action']}>
          <Button
            color="primary"
            className="btn-main btn-outline"
            onClick={action}
          >
            saiba mais
          </Button>
        </div>
      </div>
    </div>
  );
};

CustomCard.propTypes = {};
CustomCard.defaultProps = {};

export default CustomCard;
