import { get } from './base';

const url = 'noticia';

const noticiaApi = {
  fetch: (page, size) => {
    return get(`${url}s?page=${page || 1}&pageSize=${size || 999}`);
  },
  get: (id) => get(`${url}/${id}`),
};
export default noticiaApi;
