import React, { useRef, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

const Preload = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const usuario = useSelector((state) => state.usuario.usuario);
  const UID = useSelector((state) => state.auth.UID);
  const loadingRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const isLoading = isAuth === null || UID === null || (isAuth && !usuario);

  useEffect(() => {
    const event = () => setIsLoaded(true);
    const ref = loadingRef.current;
    if (!ref) return;
    if (!isLoading) {
      ref.addEventListener('animationend', event, {
        once: true,
      });
    }
    return () => ref.removeEventListener('animationend', event);
  }, [isLoading]);

  return (
    <>
      {!isLoaded && (
        <div
          ref={loadingRef}
          className="loading-wrapper"
          datatype={isLoading ? 'loading' : 'loaded'}
        >
          <div className="loading-overlay">
            <Spinner />
          </div>
          <div className="loading-backdrop" />
        </div>
      )}
      <div>{!isLoading && <Outlet />}</div>
    </>
  );
};

export default Preload;
