import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate, createSearchParams } from 'react-router-dom';

import { Button, Col, Container, Row } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { listaCategoriasConvenio } from '../utils/helpers';
import MagnifyIcon from 'mdi-react/MagnifyIcon';

import Select from '../shared/components/Form/Select';
import CheckBox from '../shared/components/Form/CheckBox';
import GoogleLocationSearch from './GoogleLocationSearch';
import useGeolocation from '../hooks/useGeolocation';

const validate = (values, [geo, error]) => {
  const errors = {};
  if (values.localAtual) {
    if (error || !geo) {
      errors.localAtual = 'Localização não disponível';
    }
  }
  return errors;
};

const ConvenioPesquisa = ({ action, search, simple }) => {
  const navigate = useNavigate();
  const { location: geolocation, error } = useGeolocation();
  const [location, setLocation] = useState();

  const initial = useMemo(() => {
    if (!search) return {};
    let cat = search.get('categoria');
    return {
      nome: search.get('nome'),
      categoria: listaCategoriasConvenio.find((item) => item.value === cat),
      online: !!+search.get('online'),
      localAtual: !!+search.get('atual'),
    };
  }, [search]);

  const handleSubmit = (values) => {
    const data = {};
    if (values.nome) data.nome = values.nome;
    if (values.categoria?.value) data.categoria = values.categoria?.value;
    if (values.online) data.online = +!!values.online;
    if (values.localAtual) {
      data.atual = +!!values.localAtual;
      if (geolocation) {
        data.loc = String([geolocation.lat, geolocation.lng]);
      }
    } else if (location) {
      data.loc = String([location.lat, location.lng]);
    }
    if (action) {
      action(data);
      return;
    }

    window.scrollTo(0, 0);
    navigate({
      pathname: '/pesquisar-convenios',
      search: createSearchParams(data).toString(),
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initial}
      validate={(values) => validate(values, [geolocation, error])}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className="d-flex flex-grow-1">
          <Container fluid>
            <Row>
              <Col
                xs={12}
                lg={simple ? 4 : 3}
                className="d-flex gap-2 align-items-center mb-3 mb-lg-0"
              >
                <MagnifyIcon />
                <Field
                  name="nome"
                  placeholder="estabelecimento, produto ou serviço"
                  className="flex-grow-1 input-border"
                  component="input"
                />
              </Col>
              <Col
                xs={6}
                lg={simple ? 3 : 2}
                className="d-flex align-items-center mb-3 mb-lg-0"
              >
                <Field
                  name="categoria"
                  className="flex-grow-1 font-Input-value"
                  component={Select}
                  options={listaCategoriasConvenio}
                  placeholder="categorias"
                />
              </Col>

              {!simple && (
                <>
                  <Col
                    xs={6}
                    lg={2}
                    className="d-flex align-items-center mb-3 mb-lg-0"
                  >
                    <GoogleLocationSearch
                      className="flex-grow-1 input-border"
                      disabled={values.localAtual}
                      onChoice={(loc) => setLocation(loc)}
                      onChange={() => location && setLocation(null)}
                      placeholder="localização"
                    />
                  </Col>
                  <Col
                    xs={6}
                    lg={2}
                    className="d-flex justify-content-center align-items-center flex-column mb-3 mb-lg-0"
                  >
                    <Field
                      name="localAtual"
                      component={CheckBox}
                      type="checkbox"
                      label="localizacão atual"
                    />
                  </Col>
                </>
              )}
              <Col
                xs={6}
                lg={simple ? 2 : 1}
                className="d-flex align-items-center justify-content-center mb-3 mb-lg-0"
              >
                <Field
                  name="online"
                  component={CheckBox}
                  type="checkbox"
                  label="online"
                />
              </Col>
              <Col
                xs={12}
                lg={simple ? 3 : 2}
                className="d-flex align-items-center"
              >
                <Button color="primary" className="btn-main w-100 px-1">
                  pesquisar
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
};

ConvenioPesquisa.propTypes = {
  action: PropTypes.func,
  search: PropTypes.any,
};
ConvenioPesquisa.defaultProps = {};

export default ConvenioPesquisa;
