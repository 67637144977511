import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';

import styles from '../scss/pages/_noticia.module.scss';
import clsx from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, Navigate, Link } from 'react-router-dom';

import NoticiasWidget from '../components/NoticiasWidget';
import RodapeSection from '../components/RodapeSection';
import { API } from '../utils/constantes';

import { getNoticia, newNoticia } from '../redux/actions/noticiaActions';

const Noticia = ({ ...args }) => {
  const dispatch = useDispatch();
  const { noticia, error, isFetching } = useSelector((state) => state.noticias);
  const { noticiaId } = useParams();

  useEffect(() => {
    if (noticia._id !== noticiaId && !isFetching) {
      dispatch(getNoticia(noticiaId));
    }
  }, [dispatch, isFetching, noticia, noticiaId]);

  useEffect(() => {
    return () => dispatch(newNoticia());
  }, [dispatch]);

  if (error) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <Container id="page-noticia" className={styles['page-noticia']}>
        <Row className="gap-5 gap-md-0">
          <Col xs={12} md={7}>
            <Container>
              <Row className="pb-4">
                <Col xs={12}>
                  <div className={styles.breadcrumbs}>
                    <Link to="/noticias" className="btn btn-minimal">
                      Notícias
                    </Link>
                    <span>{'/'}</span>
                    <span>{noticia?.titulo}</span>
                  </div>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col>
                  {noticia._id && (
                    <img
                      src={`${API}/noticia/imagem/${noticia._id}`}
                      alt="Imagem de Cabeçalho da Notícia"
                      loading="lazy"
                      className={clsx(styles.header, 'img-fluid')}
                    />
                  )}
                </Col>
              </Row>
              <Row className="pb-3">
                <Col>
                  <h1>{noticia?.titulo}</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="noticia-widget__content">
                    {noticia?.descricao}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} md={{ offset: 1, size: 4 }}>
            <NoticiasWidget />
          </Col>
        </Row>
      </Container>
      <RodapeSection />
    </>
  );
};

export default Noticia;
