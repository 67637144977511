import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Container, Row, Button, Spinner } from 'reactstrap';
import CustomCard from '../components/CustomCard';
import RodapeSection from '../components/RodapeSection';
import { clearNoticias, fetchNoticias } from '../redux/actions/noticiaActions';

import { API } from '../utils/constantes';

const titulo = 'Notícias';
const subtitulo = 'confira todas as notícias e novidades';

const Noticias = ({ ...args }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noticias = useSelector((state) => state.noticias.noticias);
  const totalPages = useSelector((state) => state.noticias.totalPages);
  const isFetching = useSelector((state) => state.noticias.isFetching);
  const [page, setPage] = useState(1);
  const noticiasRef = useRef();

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    dispatch(clearNoticias());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNoticias(page, 8));
  }, [dispatch, page]);

  const handleNavigate = (id) => () => {
    window.scrollTo(0, 0);
    navigate(`/noticia/${id}`);
  };

  const handlePage = (n) => () => {
    if (page + n < 1 || page + n > totalPages || isFetching) return;
    noticiasRef.current.scrollIntoView();
    setPage((prev) => prev + n);
  };

  return (
    <div id="page-noticias">
      <Container className="mt-5 mb-4">
        <Row>
          <Col xs={12}>
            <h1>{titulo}</h1>
            <h2 className="font-size-big">{subtitulo}</h2>
          </Col>
        </Row>
      </Container>
      <div className="bg-light py-4" ref={noticiasRef}>
        <Container>
          <Row className="position-relative">
            {isFetching && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}
            {noticias?.map((noticia) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={noticia._id}
                className="mb-4 p-3"
              >
                <CustomCard
                  imagem={`${API}/noticia/imagem/${noticia._id}`}
                  titulo={noticia.titulo}
                  texto={noticia.descricao}
                  action={handleNavigate(noticia._id)}
                  wholeCardAction
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={6} className="mx-auto">
              <div className="w-100 d-flex justify-content-center align-items-center gap-3">
                <Button
                  className="px-3 rounded-circle"
                  onClick={handlePage(-1)}
                >
                  {'<'}
                </Button>
                <span>
                  {page}
                  {totalPages && totalPages !== 1 && ` de ${totalPages}`}
                </span>
                <Button className="px-3 rounded-circle" onClick={handlePage(1)}>
                  {'>'}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <RodapeSection />
    </div>
  );
};

export default Noticias;
