import React, { useRef } from 'react';
import { Form, FormSpy, Field } from 'react-final-form';
import { Button, Col, Container, Row } from 'reactstrap';
import {
  listEmpresaAssociada,
  estadoCivilList,
  formatarCpf,
  formatFields,
  sexoList,
  noMask,
} from '../../../utils/helpers';
import FormField from '../../../shared/components/Form/FormField';
import { validatePessoais } from './validate';
import Select from '../../../shared/components/Form/Select';

export const DadosPessoais = ({ userData, setUserData, next, progressBar }) => {
  const formRef = useRef();

  const handleSubmit = (data) => {
    data.cpf = noMask(data.cpf);
    data.dataNascimento = noMask(data.dataNascimento);

    progressBar(20)
    setUserData(data);
    next();
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validatePessoais}
        initialValues={userData}
      >
        {/* <Form onSubmit={() => {}}> */}
        {({ handleSubmit, form, form: { reset, pristine, submitting } }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormSpy
                subscription={{ value: true }}
                onChange={({ values }) => {
                  const fields = [{ name: 'cpf', mask: formatarCpf }];
                  formatFields(fields, formRef);
                }}
              />
              <Container className="margin-input-from-img p-0">
                <Row>
                  <Col md={12}>
                    <label>Nome</label>
                    <Field
                      name="nome"
                      className="input-border w-100"
                      type="string"
                      placeholder="Ex: Antonio Carlos"
                      rules={{ required: 'Campo Obrigatório' }}
                      maxLength="50"
                      component={FormField}
                    />
                  </Col>

                  <Col md={12}>
                    <label className="label-mt-top">CPF</label>
                    <Field
                      name="cpf"
                      type="string"
                      className="input-border w-100"
                      rules={{ required: true }}
                      component={FormField}
                      minLength="14"
                      maxLength="14"
                      placeholder="Ex: 000.000.000-00"
                      format={(cpf) => (cpf ? formatarCpf(cpf) : '')}
                    />
                  </Col>
                  <Col md={12}>
                    <label className="label-mt-top">RG</label>
                    <Field
                      name="rg"
                      component={FormField}
                      className="input-border w-100"
                      type="text"
                      placeholder="Digite o RG"
                    />
                  </Col>
                  <Col md={12}>
                    <label className="label-mt-top">Data de Nascimento</label>
                    <Field
                      name="dataNascimento"
                      component={FormField}
                      className="input-border w-100"
                      type="Date"
                      placeholder="00/00/0000"
                      minLength="8"
                      maxLength="8"
                    />
                  </Col>
                  <Col md={12}>
                    <label className="label-mt-top">Matrícula</label>
                    <Field
                      name="matricula"
                      component="input"
                      className="input-border w-100"
                      type="text"
                      placeholder="Digite a matrícula"
                      maxLength="10"
                    />
                  </Col>
                  <Col md={12}>
                    <label className="label-mt-top" htmlFor="selectSexo">
                      Sexo
                    </label>
                    <Field
                      name="sexo"
                      type="select"
                      id="selectSexo"
                      className="font-Input-value w-100"
                      component={Select}
                      options={sexoList}
                      placeholder="Selecione um campo"
                    />
                  </Col>
                  <Col md={12}>
                    <label className="label-mt-top" htmlFor="selectEstadoCivil">
                      Estado Civil:
                    </label>
                    <Field
                      type="select"
                      name="estadoCivil"
                      id="selectEstadoCivil"
                      className="font-Input-value w-100"
                      component={Select}
                      options={estadoCivilList}
                      placeholder="Selecione um campo"
                    />
                  </Col>
                  <Col md={12}>
                    <label className="label-mt-top">
                      Empresa na qual Trabalha
                    </label>
                    <Field
                      name="empresaVinculo"
                      id="selectEmpresaVinculo"
                      className="font-Input-value w-100"
                      component={Select}
                      options={listEmpresaAssociada}
                      placeholder="Selecione um campo"
                    />
                  </Col>
                  <Row>
                    <Col>
                      <Button color="primary" className="mt-5">
                        Próximo
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Container>
            </form>
          );
        }}
      </Form>
    </>
  );
};
