import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Carousel, CarouselItem } from 'react-bootstrap';
import { Container, Row, Col, Button } from 'reactstrap';
import { fetchBanners } from '../../redux/actions/bannerActions';

import { API } from '../../utils/constantes';

const titulo = 'associe-se agora';
const subtitulo = 'e aproveite benefícios exclusivos';

const Hero = ({ ...args }) => {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.banners.banners);

  useEffect(() => {
    dispatch(fetchBanners(1, 4));
  }, [dispatch]);

  const bannerButton = (banner) => {
    if (!banner.link) {
      return null;
    }
    return (
      <Link to={banner?.link} className="text-btn">
        <Button color="secondary" className="btn-main btn-big mt-5">
          <span className="text-btn ">{banner?.textoBotao}</span>
        </Button>
      </Link>
    );
  };

  return (
    <section className="section pt-0" id="home">
      <Carousel className="p-0" interval={5000} pause="hover">
        {banners.map((banner, i) => (
          <CarouselItem key={i} className="p-0">
            <Container
              className="section home-container-item-carousel bg-home d-flex flex-column justify-content-around m-0"
              style={{
                backgroundImage: `url(${API}/banner/imagem/${banner._id})`,
              }}
            >
              <Row className="linha-conteudo-hero">
                <Col xs={12} md={5}>
                  <h1 className="fw-bold" style={{ color: 'white' }}>
                    {banner?.titulo}
                  </h1>
                  {bannerButton(banner)}
                </Col>
              </Row>
            </Container>
          </CarouselItem>
        ))}
      </Carousel>
    </section>
  );
};

export default Hero;
