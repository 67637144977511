import React from "react";
import TimerSandIcon from "mdi-react/TimerSandIcon";


export const Confirmacao = ({ userData, setUserData, next, prev, setStep }) => {
  return (
    <div className="d-flex flex-column align-items-center gap-3 pt-3">
      <div>
        <h1>Solicitação Criada</h1>
      </div>
      <div className="pt-3">
        <TimerSandIcon size={70} color={'green'}/>
      </div>
      <div className="m-5 text-center">
        <p>Aguarde análise e aprovação do seu pedido. Você será informado sobre o processo no email de cadastro.</p>
      </div>
    </div>
  );
};
