import React, { useRef, useEffect } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Button, Col, Container, Row } from 'reactstrap';
import FormField from '../../../shared/components/Form/FormField';
import {
  cepMask,
  maskPhone,
  maskPhoneComercial,
  noMask,
  formatFields,
} from '../../../utils/helpers';
import { regexNaN } from '../../../utils/constantes';
import { validateTelEndereco } from './validate';
import { getCEP } from '../../../redux/actions/cepActions';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from 'mdi-react/SearchIcon';

export const DadosEnderecoTelefone = ({
  userData,
  setUserData,
  next,
  prev,
  setStep,
  progressBar,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef({});

  const cep = useSelector((state) => state.cep.cep);
  const cepIsFetching = useSelector((state) => state.cep.isFetching);

  useEffect(() => {
    if (Object.keys(cep).length === 0 || cep?.erro) {
      return;
    }
    let delivery_address = `${cep.logradouro}${
      cep.complemento ? ` ${cep.complemento}` : ''
    }, ${cep.bairro}`;
    delivery_address += `, ${cep.localidade} - ${cep.uf}`;
    formRef?.current.change('CEP', cep.cep);
    formRef?.current.change('endereco', delivery_address);
  }, [cep]);

  const submitCep = () => {
    const formCep = formRef.current.getState().values.cep;
    if (!formCep) {
      return;
    }
    let value = formCep.replace(regexNaN, '');
    dispatch(getCEP(value));
  };

  const handleSubmit = (data) => {
    data.cep = noMask(data.cep);
    data.telCelular = noMask(data.telCelular);
    data.telComercial = noMask(data.telComercial);
    progressBar(20)
    setUserData(data);
    next();
  };

  const handleClick = () => {
    prev();
    progressBar(-20);
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={userData}
        validate={validateTelEndereco}
      >
        {({ handleSubmit, form }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ valid: true }}
                onChange={(props) => {
                  const fields = [{ name: 'cep', mask: cepMask }];
                  formatFields(fields, formRef);
                }}
              />
              <Container className="margin-input-from-img p-0">
                <Row>
                  <label>CEP </label>
                  <Col xs={11}>
                    <Field
                      name="cep"
                      component={FormField}
                      className="input-border w-100"
                      type="string"
                      placeholder="Ex: 00000-000"
                      maxLength="9"
                      format={(cep) => (cep ? cepMask(cep) : '')}
                    />
                  </Col>
                  <Col xs={1}>
                    <button
                      // className="btn-style-icons"
                      type="submit"
                      disabled={cepIsFetching}
                      onClick={submitCep}
                    >
                      <SearchIcon className="btn-icon-form" />
                    </button>
                  </Col>
                </Row>
                <Col md={12}>
                  <label className="label-mt-top">
                    Endereço completo (logradouro, número e complemento)
                  </label>
                  <Field
                    name="endereco"
                    component={FormField}
                    className="input-border w-100"
                    type="text"
                    placeholder="Ex: Quadra F Santa Maria da Codipe Teresina"
                    maxLength="50"
                  />
                </Col>
                <Col md={12}>
                  <label className="label-mt-top">Tel comercial </label>
                  <Field
                    name="telComercial"
                    component={FormField}
                    className="input-border w-100"
                    type="text"
                    placeholder="Ex: (00) 0000-0000"
                    maxLength="14"
                    format={(telComercial) =>
                      telComercial ? maskPhoneComercial(telComercial) : ''
                    }
                  />
                </Col>
                <Col md={12}>
                  <label className="label-mt-top">Tel celular </label>
                  <Field
                    name="telCelular"
                    component={FormField}
                    className="input-border w-100"
                    type="string"
                    placeholder="Ex: (00) 00000-0000"
                    maxLength="15"
                    format={(telCelular) =>
                      telCelular ? maskPhone(telCelular) : ''
                    }
                  />
                </Col>
                <Col md={12}>
                  <label className="label-mt-top">Nome do Pai</label>
                  <Field
                    name="nomePai"
                    component={FormField}
                    className="input-border w-100"
                    type="text"
                    placeholder="Ex: Joaquim De Souza"
                    maxLength="50"
                  />
                </Col>
                <Col md={12}>
                  <label className="label-mt-top">Nome da Mãe</label>
                  <Field
                    name="nomeMae"
                    component={FormField}
                    className="input-border w-100"
                    type="text"
                    placeholder="Ex: Maria Rosa"
                    maxLength="50"
                  />
                </Col>
                <Button
                  color="gray"
                  className="mt-5 m-3"
                  /*className="btn-style-anterior"*/ onClick={handleClick}
                >
                  anterior
                </Button>
                <Button
                  color="primary"
                  className="mt-5 m-3" /*className="btn-style-prox"*/
                >
                  Próximo
                </Button>
              </Container>
            </form>
          );
        }}
      </Form>
    </>
  );
};
