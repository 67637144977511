import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { useSelector } from 'react-redux';

import { Status } from '@googlemaps/react-wrapper';
import Marker from './Marker';
import useGoogleApi from '../../hooks/useGoogleApi';
import { LocalizacaoPadrao } from '../../utils/helpers';

const mapProps = {
  center: LocalizacaoPadrao,
  zoom: 12,
};

const ConvenioMapa = ({ centro }) => {
  const status = useGoogleApi();

  const mapRef = useRef(null);
  const [map, setMap] = useState();
  const convenios = useSelector((state) => state.convenios.convenios);
  const infoWindow = useMemo(
    () => status === Status.SUCCESS && new window.google.maps.InfoWindow(),
    [status]
  );

  const handleMarkerClick = useCallback(
    (addr) => (marker) => {
      const content = `
          <div class="infobox-googlemaps">
            <h4>${marker.getTitle()}</h4>
            <p>${addr.street}, ${addr.number}</p>
            ${addr.neighborhood ? '<p>' + addr.neighborhood + '</p>' : ''}
            <p>${addr.county}/${addr.state}</p>
            <p>${addr.cep}</p>
          </div>
          `;

      infoWindow?.close();
      infoWindow?.setContent(content);
      infoWindow?.open(marker.getMap(), marker);
    },
    [infoWindow]
  );

  const markers = useMemo(() => {
    return convenios.map((item) =>
      item.addressess.map((addr) => (
        <Marker
          key={item.id + addr._id}
          position={{
            lng: +addr.location.coordinates[0],
            lat: +addr.location.coordinates[1],
          }}
          map={map}
          title={item.name}
          onClick={handleMarkerClick(addr)}
        />
      ))
    );
  }, [convenios, map, handleMarkerClick]);

  const current = mapRef.current;
  useEffect(() => {
    if (current && !map && status === Status.SUCCESS) {
      setMap(new window.google.maps.Map(current, mapProps));
    }
  }, [current, map, status]);

  useEffect(() => {
    if (current && map) {
      map.setCenter(centro);
    }
  }, [current, map, centro]);

  return (
    <>
      <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
      {markers}
    </>
  );
};

export default ConvenioMapa;
