import { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { GoogleContext } from '../components/GoogleApiManager';

const useGoogleApi = (lib) => {
  const { register, unregister, status } = useContext(GoogleContext);

  const [id] = useState(uuid());
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    if (!registered) {
      register(id);
      setRegistered(true);
    }
  }, [id, register, unregister, registered]);

  // useEffect(() => {
  //   addLibrary(lib);
  // }, [lib, addLibrary]);

  return status;
};

export default useGoogleApi;
