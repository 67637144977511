import { createReducer } from '@reduxjs/toolkit';
import { errorHandler } from '../../utils/error';
import * as actions from '../actions/usuarioActions';

const initialState = {
  usuario: {},
  isFetching: false,
  error: null,
};

const usuarioReducer = createReducer(initialState, {
  [actions.getUsuarioRequest]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [actions.getUsuarioSuccess]: (state, { payload }) => ({
    ...state,
    usuario: payload,
    isFetching: true,
    error: null,
  }),
  [actions.getUsuarioError]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    error: errorHandler(payload),
  }),
  [actions.newUsuario]: (state, { payload }) => ({
    ...state,
    usuario: {},
  }),
});

export default usuarioReducer;
