const validate = ({ email, password }) => {
  const errors = {};
  if (!email) {
    errors.email = 'Por favor insira seu usuário';
  }
  if (!password) {
    errors.password = 'Por favor insira sua senha';
  }
  return errors;
};
export default validate;
