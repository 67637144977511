import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toggleLoginModal } from '../redux/actions/modalsActions';
import LoginForm from './LoginForm';

const LoginModal = ({ ...args }) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modals.login);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const [render, setRender] = useState(true);

  const toggle = () => {
    dispatch(toggleLoginModal());
  };

  useEffect(() => {
    let timeout;
    if (isAuth && open) {
      timeout = setTimeout(() => {
        dispatch(toggleLoginModal(false));
        setRender(false);
      }, 1000);
    } else {
      setRender(true);
    }
    return () => clearTimeout(timeout);
  }, [open, isAuth, dispatch]);

  if (isAuth && !render) {
    return <></>;
  }

  return (
    <Modal
      {...args}
      isOpen={open}
      toggle={toggle}
      className="modal-full-height no-header"
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody className="d-flex justify-content-center">
        <LoginForm onFinish={toggle} toggle={toggle} />
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;
