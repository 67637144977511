import React, { useEffect } from 'react';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { API } from '../utils/constantes';

import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { fetchNoticias } from '../redux/actions/noticiaActions';
import styles from '../scss/components/_noticias-widget.module.scss';

const NoticiasWidget = ({ ...args }) => {
  const dispatch = useDispatch();
  const noticias = useSelector((state) => state.noticias.noticias);

  useEffect(() => {
    dispatch(fetchNoticias(1, 10));
  }, [dispatch]);

  return (
    <Card className={styles['noticias-widget']}>
      <CardBody>
        <CardTitle>
          <h3>Últimas Notícias</h3>
        </CardTitle>
        <div className={styles.content}>
          {noticias.length === 0 &&
            new Array(5).fill().map((_, i) => (
              <Container className={styles.noticia} key={i}>
                <Row>
                  <Col xs={5}>
                    <div className="skeleton-rect" />
                  </Col>
                  <Col xs={7}>
                    <div className={styles['noticia-content']}>
                      <h4 className="skeleton-text"> </h4>
                      <p className="skeleton-text" />
                    </div>
                  </Col>
                </Row>
              </Container>
            ))}
          {noticias.map((noticia, i) => (
            <Link to={`/noticia/${noticia._id}`} key={i}>
              <Container className={styles.noticia}>
                <Row>
                  <Col xs={5}>
                    <div
                      className={styles.imagem}
                      style={{ backgroundImage: `url(${API}/noticia/imagem/${noticia._id})` }}
                    ></div>
                  </Col>
                  <Col xs={7}>
                    <div className={styles['noticia-content']}>
                      <h4>{noticia.titulo}</h4>
                      <p>{moment(noticia.createdAt).format('DD MMMM YYYY')}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Link>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

NoticiasWidget.propTypes = {};
NoticiasWidget.defaultProps = {};

export default NoticiasWidget;
