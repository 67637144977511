import { get } from './base';

const bannerApi = {
  fetchBanners: (page, pageSize) =>
    get(`/banners?canal=website`, {
      params: {
        canal: 'website',
        page,
        pageSize,
      },
    }),
};

export default bannerApi;
