import React, { useEffect } from "react";
import styles from "../scss/components/_login-form.module.scss";
import PropTypes from "prop-types";
import clsx from "classnames";

import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Button, Spinner } from "reactstrap";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import CheckBoldIcon from "mdi-react/CheckBoldIcon";
import Logo from "./Logo";

import { recuperarSenha } from "../redux/actions/authActions";
import SimpleNotification from "./SimpleNotification";
import { clearAll } from "../redux/actions/notificacoesActions";

const RecuperarSenhaForm = ({ logo, onFinish, toggle, className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFetching = useSelector((state) => state.auth.isFetching);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const success = useSelector((state) => state.notificacoes.success);
  const error = useSelector((state) => state.notificacoes.error);

  const handleSubmit = ({ email }) => {
    dispatch(clearAll());
    dispatch(recuperarSenha(email));
  };

  const redirecionarHome = () => {
    toggle && toggle();
    navigate("/");
  };

  useEffect(() => () => dispatch(clearAll()), [dispatch]);

  return (
    <div className={clsx(styles["login-form__wrapper"], className)}>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit, errors, touched }) => {
          const emailError =
            errors["login"] && touched["login"] ? "error" : undefined;
          return (
            <form
              onSubmit={handleSubmit}
              className={clsx(styles["login-form"], "container-fluid")}
            >
              <Row>
                {logo && (
                  <Col xs={12} className="pb-2">
                    <Logo variant="color" size="big" />
                  </Col>
                )}
                <Col xs={12}>
                  <h2>Recuperação da Senha</h2>
                </Col>
                <Col xs={12} className="pt-3 pb-1 text-center">
                  <h5>
                    Digite o seu e-mail. Você receberá um e-mail com instruções
                    sobre como redefinir a sua senha.
                  </h5>
                </Col>

                <Col xs={12}>
                  <SimpleNotification success={success} error={error} />
                </Col>
              </Row>
              <Row xs={12} className={styles["login-form__content"]}>
                <Col xs={12}>
                  <Field
                    type="text"
                    component="input"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    className="input-border w-100"
                    error={emailError}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-1">
                  {!success && (
                    <Button
                      color="primary"
                      disabled={isFetching || isAuth}
                      className="px-5"
                    >
                      {isAuth
                        ? "Autenticado"
                        : isFetching
                        ? "Autenticando"
                        : "Enviar"}
                    </Button>
                  )}
                  {success && (
                    <Button
                      // color="primary"
                      color="gray"
                      disabled={isFetching || isAuth}
                      className="px-5"
                      onClick={redirecionarHome}
                    >
                      Voltar
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          );
        }}
      </Form>
      {(isFetching || isAuth) && (
        <div
          className={clsx(styles["login-form__loading"], {
            [styles["login-form__success"]]: isAuth,
          })}
        >
          {isAuth ? <CheckBoldIcon /> : <Spinner />}
        </div>
      )}
    </div>
  );
};

RecuperarSenhaForm.propTypes = {
  logo: PropTypes.bool,
  toggle: PropTypes.func,
  onFinish: PropTypes.func,
  className: PropTypes.string,
};
RecuperarSenhaForm.defaultProps = {
  logo: true,
};

export default RecuperarSenhaForm;
