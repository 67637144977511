import { createAction } from '@reduxjs/toolkit';
import bannerApi from '../../utils/api/bannerApi';
import { errorHandler } from '../../utils/error';
import { displayError } from './notificacoesActions';
import { arrayToBase64 } from '../../utils/helpers';

export const fetchBannersRequest = createAction('FETCH_BANNERS_REQUEST');
export const fetchBannersSuccess = createAction('FETCH_BANNERS_SUCCESS');

export const getBannerSuccess = createAction('GET_BANNER_SUCCESS');

export const fetchBanners = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchBannersRequest());
    const { data } = await bannerApi.fetchBanners(page, pageSize);
    if (data.imagem?.data?.data) {
      data.imagem = arrayToBase64(data.imagem.data.data);
    }
    dispatch(fetchBannersSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(displayError(e));
  }
};

// export const getBanner = (page, pageSize) => async (dispatch) => {
//   dispatch(genericBannerRequest());
//   try {
//     const { data } = await bannerApi.get(page, pageSize);
//     if (data.imagem?.data?.data) {
//       data.imagem = arrayToBase64(data.imagem.data.data);
//     }
//     dispatch(genericBannerSuccess());
//     dispatch(getBannerSuccess(data));
//   } catch (error) {
//     console.error(error);
//     dispatch(genericBannerError(errorHandler(error)));
//     dispatch(displayError('Ocorreu um erro carregar o Banner'));
//   }
// };
