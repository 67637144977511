import React, { Fragment, useRef, useState } from 'react';
import { Form, FormSpy, Field } from 'react-final-form';
import { Row, Col, Button, Container } from 'reactstrap';
import { noMask } from '../../../utils/helpers';
import { validateDadosLogin } from './validate';
import FormField from '../../../shared/components/Form/FormField';
import EyeIcon from 'mdi-react/EyeIcon';
import SimpleNotification from '../../../components/SimpleNotification';
import { useDispatch, useSelector } from 'react-redux';
import { createAssociarSe } from '../../../redux/actions/associadoActions';

const leiLGPD =
  'Considerando o disposto na LGPD - LEI Nº 13.709, DE 14 DE AGOSTO DE 2018, favor preencher o campo abaixo:';

const autorizoAscesa =
  'Autorizo que a Ascesa envie, para meu conhecimento, informações sobre a administração e gestão da Associação e campanhas  promocionais relacionadas aos parceiros da Ascesa. Favor, escolha o(s) meio(s):';

const confirmoVeracidade =
  'Confirmo a veracidade das informações acima e estou de acordo com a política de acesso e termos de uso da ASCESA e estou de acordo com a cobrança de R$20 mensais.';

const checkBoxMidias = [
  { value: 'Email' },
  { value: 'Mídias Sociais (Whatsapp e Facebook)' },
  { value: 'SMS' },
];

export const DadosLogin = ({
  userData,
  setUserData,
  next,
  prev,
  setStep,
  progressBar,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState();
  const [passwordConfShow, setPasswordConfShow] = useState();
  const isFetching = useSelector((state) => state.associados.isFetching);
  const error = useSelector((state) => state.notificacoes.error);
  const success = useSelector((state) => state.notificacoes.success);

  let errorCopy = error;
  let successCopy = success;

  const handleSubmit = async (values) => {
    const data = { ...values };
    const newCep = noMask(data.cep);
    const newCpf = noMask(data.cpf);
    const newTeCelular = noMask(data.telCelular);
    const newTelComercial = noMask(data.telComercial);

    data.empresaVinculo = data.empresaVinculo?.value;
    data.estadoCivil = data.estadoCivil?.value;
    data.sexo = data.sexo?.value;

    data.cep = newCep;
    data.cpf = newCpf;
    data.telCelular = newTeCelular;
    data.telComercial = newTelComercial;

    if (data.dependentes) {
      data.dependentes.cpf = noMask(data.dependentes?.cpf);
      data.dependentes.dataNascimento = noMask(
        data.dependentes?.dataNascimento
      );
    }

    progressBar(20);
    setUserData(data);
    const success = await dispatch(createAssociarSe(data));
    if (success) {
      errorCopy = null;
      successCopy = null;
      next();
    }
  };

  const handleClick = () => {
    prev();
    progressBar(-20);
  };

  return (
    <>
      <SimpleNotification error={errorCopy} success={successCopy} />
      <Form
        onSubmit={handleSubmit}
        initialValues={userData}
        validate={validateDadosLogin}
      >
        {({
          handleSubmit,
          values,
          form,
          form: { reset, pristine, submitting },
        }) => {
          formRef.current = form;
          return (
            <form onSubmit={handleSubmit}>
              <FormSpy subscription={{ value: true }} onChange={() => {}} />
              <Container className="margin-input-from-img p-0">
                <Row>
                  <Col md={12}>
                    <label className="label-mt-top">Email corporativo</label>
                    <Field
                      name="email"
                      component={FormField}
                      className="input-border w-100"
                      type="email"
                      placeholder="Ex: antonio@email.com"
                      maxLength="70"
                    />
                  </Col>
                </Row>
                <Row>
                  <label className="label-mt-top">Senha</label>
                  <Col xs={11}>
                    <Field
                      name="password"
                      component={FormField}
                      type={passwordShow ? 'text' : 'password'}
                      className="input-border w-100"
                      placeholder="Senha"
                      maxLength="70"
                    />
                  </Col>
                  <Col xs={1}>
                    <button
                      // className="btn-style-icons"
                      type="button"
                      onClick={() => setPasswordShow((prev) => !prev)}
                    >
                      <EyeIcon className="btn-icon-form" />
                    </button>
                  </Col>
                </Row>
                <Row>
                  <label className="label-mt-top">Confirme sua Senha</label>
                  <Col xs={11}>
                    <Field
                      name="passwordConf"
                      component={FormField}
                      type={passwordConfShow ? 'text' : 'password'}
                      className="input-border w-100"
                      placeholder="Senha"
                      maxLength="70"
                    />
                  </Col>
                  <Col xs={1}>
                    <button
                      // className="btn-style-icons"
                      type="button"
                      onClick={() => setPasswordConfShow((prev) => !prev)}
                    >
                      <EyeIcon className="btn-icon-form" />
                    </button>
                  </Col>
                </Row>
                <label className="label-mt-top">{leiLGPD}</label>
                <span htmlFor="check-confirm-lgpd" className="font-small">
                  {autorizoAscesa}
                </span>
                <div className="smaller-margin-area"></div>
                {checkBoxMidias.map((item, index) => (
                  <Fragment key={index}>
                    <input
                      id={`check-confirm-midias-${index}`}
                      type="checkbox"
                    />
                    <label
                      className="label-check"
                      htmlFor={`check-confirm-midias-${index}`}
                    >
                      {item.value}
                    </label>
                    <br />
                  </Fragment>
                ))}
                <label className="label-mt-top">Confirmação</label>
                <div className="smaller-margin-area"></div>
                <Field
                  type="checkbox"
                  id="check-confirm"
                  name="confirmVeracidade"
                  component={FormField}
                />
                <small
                  htmlFor="check-confirm"
                  className="span-margin-from-btn-check"
                >
                  {confirmoVeracidade}
                </small>
              </Container>
              <Row>
                <Col>
                  <Button
                    color="gray"
                    className="mt-5 m-3"
                    onClick={handleClick}
                    disabled={isFetching}
                    /*className="btn-style-anterior"*/
                  >
                    anterior
                  </Button>
                  <Button
                    color="primary"
                    className="mt-5 m-3" /*className="btn-style-prox"*/
                    disabled={isFetching}
                  >
                    salvar
                  </Button>
                </Col>
              </Row>
            </form>
          );
        }}
      </Form>
    </>
  );
};
