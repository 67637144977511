import React from 'react';
import styles from '../scss/components/_simple-notification.module.scss';
import clsx from 'classnames';

const SimpleNotification = ({ className, error, success }) => {
  return (
    <div
      className={clsx(className, styles.notification, {
        [styles.error]: error,
        [styles.success]: success,
      })}
    >
      {success || error}
    </div>
  );
};

export default SimpleNotification;
